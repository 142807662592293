import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

function SponsorItem({ src, link }) {
  return (
    <Item>
      <a href={link} target="_blank">
        <img src={src} />
      </a>
    </Item>
  )
}
function SponsorList({ items, top, left }) {
  if (isMobile) {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 83px)',
          alignItems: 'center',
          width: '100vw',
          justifyContent: 'center',
        }}
      >
        {items.map((item) => {
          return (
            <a href={item.link}>
              <img width="70" src={item.src} />
            </a>
          )
        })}
      </div>
    )
  }
  return (
    <StyleWrapper top={top} left={left}>
      {items.map((item) => (
        <SponsorItem src={item.src} link={item.link} />
      ))}
    </StyleWrapper>
  )
}

export default SponsorList

const StyleWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: ${(props) => props.top}px;
  width: 520px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translate(calc(-50% + ${(props) => props.left}px), 100px);
`
const Item = styled.div``
