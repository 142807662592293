import { atom, selector } from 'recoil'
import data from '../data/people.json'

export const menuState = atom({
  key: 'menuState',
  default: {
    isOpen: false,
  },
})

export const clipImgIndexState = atom({
  key: 'clipImgIndexState',
  default: 0,
})

export const infoModalIsOpenState = atom({
  key: 'infoModalIsOpen',
  default: false,
})
export const infoModalPersonIndexState = atom({
  key: 'infoModalPersonIndexState',
  default: 0,
})
export const infoModalDataState = atom({
  key: 'infoModalDataState',
  default: data,
})

export const infoModalPersonState = selector({
  key: 'infoModalPersonState',
  get: ({ get }) => {
    const data = get(infoModalDataState)
    const index = get(infoModalPersonIndexState)
    return data[index]
  },
})
export const portfolioState = atom({
  key: 'portfolioState',
  default: [],
})
export const scrollState = atom({
  key: 'scrollState',
  default: {
    y: 0,
  },
})

export const teacherInfoModalIsOpenState = atom({
  key: 'teacherInfoModalIsOpen',
  default: false,
})
export const teacherInfoModalDataState = atom({
  key: 'teacherInfoModalDataState',
  default: {
    title: [],
    name: '',
    description: '',
    pic: '',
  },
})

export const worksDataState = atom({
  key: 'worksDataState',
  default: [],
})
