import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { isMobile, isBrowser } from 'react-device-detect'
import { device } from '../../utils/mediaQueryDevice'
import IntersectionVisible from 'react-intersection-visible'

function SectionPart({
  animationTitle,
  anchorId,
  content,
  useTableletLStyle,
  style,
  desktopTitleImgSrc,
  contentStyle,
}) {
  const [isIntersection, setIsIntersection] = React.useState(false)
  return (
    <div style={style}>
      <Wrapper id={anchorId} isIntersection={isIntersection}>
        {desktopTitleImgSrc && (
          <img
            style={{
              height: '100%',
              position: 'absolute',
              left: -300,
            }}
            src={desktopTitleImgSrc}
          />
        )}

        <Content
          useTableletLStyle={useTableletLStyle}
          animationTitle={animationTitle}
          style={contentStyle}
        >
          <IntersectionVisible
            onIntersect={(e) => {
              if (e[0].isIntersecting) {
                setIsIntersection(true)
              } else {
                // setIsIntersection(false)
              }
            }}
          >
            {content}
          </IntersectionVisible>
        </Content>
      </Wrapper>
    </div>
  )
}

const isIntersectionAnimation = (props) => css`
  ${props.isIntersection ? fadeIn : fadeOut} 0.1s linear forwards
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -30px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
`
const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: space-between;
  font-family: 'source-han-serif-tc';
  font-weight: 700;
  font-style: none;
  ${isMobile && `min-height: 100vh`};
  opacity: 0;
  animation: ${isIntersectionAnimation};
  animation-duration: 1s;
`

const Content = styled.div`
  position: relative;
  width: 100vw;
  flex: ${(props) => (props.animationTitle ? '4 4 0' : 'unset')};
  margin-left: ${(props) =>
    !props.animationTitle
      ? isMobile
        ? '0px'
        : '0px'
      : isMobile
      ? '0px'
      : '0px'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${() => (isMobile ? '12px' : '19px')};
  ${() => isMobile && 'margin: 0 auto'};
  letter-spacing: 2px;
  line-height: 44px;
  ${isMobile && `line-height: 35px`};
  ${isMobile && `padding: 0px 10px 0px 10px`};
  font-weight: bold;

  @media only screen and ${device.laptopL} {
    ${({ useTableletLStyle }) => useTableletLStyle && 'margin: auto auto'};
  }
  @media only screen and ${device.mobileL} {
    ${({ useTableletLStyle }) => useTableletLStyle && 'margin: auto auto'};
  }
`

export default SectionPart
