import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { clipImgIndexState } from '../../atoms/recoil-atoms'
function useInterval(callback, delay) {
  const savedCallback = React.useRef()

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const images = [
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/01.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/02.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/03.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/04.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/05.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/06.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/07.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/08.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/09.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/10.png',
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/11.png',
]

function BackgroundClip() {
  const [runningClip, setRunningClip] = React.useState(null)
  const [clipImgIndex, setClipImgIndex] = useRecoilState(clipImgIndexState)
  const [isHover, setIsHover] = React.useState(false)
  useInterval(() => {
    let nIndex = clipImgIndex + 1
    if (nIndex >= images.length) {
      nIndex = 0
    }
    setClipImgIndex(nIndex)
  }, runningClip)
  const runClip = (e) => {
    e.target.style.backgroundImage = `url(https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/Webp.net-gifmaker.gif)`
  }
  const stopClip = (e) => {
    e.target.style.backgroundImage = `url(https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/00.png)`
  }
  return (
    <StyleWrapper>
      <Link to="/departments">
        <RanbowText onMouseOver={runClip} onMouseOut={stopClip} />
      </Link>
    </StyleWrapper>
  )
}

export default BackgroundClip

const StyleWrapper = styled.div`
  font-family: 'Impact';
  font-style: none;
  font-weight: 700;
  margin: 0;
  background-size: contain;
  width: 75vw;
  text-align: left;
  > a {
    text-decoration: none;
  }
`
const RanbowText = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 800px;
  max-width: 800px;
  height: 100vh;
  background-image: url(https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/00.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
`
