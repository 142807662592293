import React from 'react'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'

import CategoryCanvas from '../../components/CategoryCanvas'
import { menuState } from '../../atoms/recoil-atoms'

function PortfolioSideBar({
  setSelectCategory,
  selectCategory,
  handleCloseMenu,
}) {
  const [menu, setMenuState] = useRecoilState(menuState)
  return (
    <SideBarContainer id="menu">
      <SideItem>
        <CategoryCanvas
          selectCategory={selectCategory.toLocaleLowerCase() === 'all'}
          label="All"
          setSelectCategory={setSelectCategory}
          handleCloseMenu={() => {
            setMenuState(false)
          }}
        />
      </SideItem>
      <SideItem>
        <CategoryCanvas
          selectCategory={selectCategory.toLocaleLowerCase() === 'graphic'}
          label="Graphic"
          setSelectCategory={setSelectCategory}
          handleCloseMenu={() => {
            setMenuState(false)
          }}
        />
      </SideItem>
      <SideItem>
        <CategoryCanvas
          selectCategory={selectCategory.toLocaleLowerCase() === 'audio'}
          label="Audio"
          setSelectCategory={setSelectCategory}
          handleCloseMenu={() => {
            setMenuState(false)
          }}
        />
      </SideItem>
      <SideItem>
        <CategoryCanvas
          selectCategory={selectCategory.toLocaleLowerCase() === 'video'}
          label="Video"
          setSelectCategory={setSelectCategory}
          handleCloseMenu={() => {
            setMenuState(false)
          }}
        />
      </SideItem>
    </SideBarContainer>
  )
}

const SideBarContainer = styled.ul`
  width: 140px;
  font-family: impact;
`
const SideItem = styled.li`
  list-style: none;
  cursor: pointer;
  color: black;
  letter-spacing: 1.2px;
  & a {
    border-bottom: 0 solid red;
    padding-bottom: 8px;
  }
  & a.active {
    color: rgb(236, 200, 239);
  }
  &:hover {
    color: rgb(236, 200, 239);
  }
`

export default PortfolioSideBar
