import React from 'react'
import { isMobile } from 'react-device-detect'

function CategoryCanvas({
  selectCategory,
  setSelectCategory,
  label,
  handleCloseMenu,
}) {
  const c = React.useRef()
  const hashChange = React.useCallback(() => {
    // window.location.hash = label
    setSelectCategory(label.toLocaleLowerCase())
    if (handleCloseMenu) handleCloseMenu()
  }, [setSelectCategory, label])
  React.useEffect(() => {
    const drawLine = () => {
      draw(true)
    }
    const clearLine = () => {
      draw(false)
    }
    const ctx = c.current.getContext('2d')
    function draw(isMouseEnter) {
      ctx.clearRect(0, 0, c.current.width, c.current.height)
      ctx.font = `${isMobile ? `40` : `60`}px impact`
      ctx.strokeStyle = '#eee070'
      ctx.textAlign = 'center'
      ctx.strokeText(label, 220 / 2, 50)
      if (selectCategory || isMouseEnter) {
        ctx.strokeStyle = '#f298f0'
        ctx.textAlign = 'center'
        ctx.strokeText(label, 220 / 2 - 4, 52)
        ctx.strokeStyle = '#92d7f1'
        ctx.textAlign = 'center'
        ctx.strokeText(label, 220 / 2 - 3, 47)
      }
    }
    requestAnimationFrame(() => draw())
    const timer = setTimeout(() => {
      draw()
    }, 1000)
    c.current.addEventListener('click', hashChange)
    c.current.addEventListener('mouseenter', drawLine)
    c.current.addEventListener('mouseout', clearLine)
    return () => {
      c.current.removeEventListener('click', hashChange)
      c.current.removeEventListener('mouseenter', drawLine)
      c.current.removeEventListener('mouseout', clearLine)
      clearTimeout(timer)
    }
  }, [label, selectCategory, hashChange])
  return (
    <canvas ref={c} width="220" height="70">
      Your browser does not support the HTML5 canvas tag.
    </canvas>
  )
}

export default CategoryCanvas
