import React from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'
import BeatLoader from 'react-spinners/BeatLoader'
import { useLocation, useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { portfolioState, scrollState } from '../../atoms/recoil-atoms'
import CategoryCanvas from '../../components/CategoryCanvas'
import Footer from '../../components/Footer'
import BG from '../../images/BG2.jpg'
import TitleImg from '../../images/標題.png'
import TitleImgPink from '../../images/標題粉.png'

function PortfolioItem({ portfolio }) {
  const [isHover, setIsHover] = React.useState(false)
  const history = useHistory()
  return (
    <div
      id={portfolio[0]}
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
      }}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'rgba(201, 192, 114, .9)',
          height: '100%',
          width: '250px',
          padding: 20,
          right: '50%',
          transform: 'translate(-15px, 0)',
          textAlign: 'center',
          top: 0,
          display: 'flex',
          opacity: isHover ? 1 : 0,
          flexDirection: 'column',
          justifyContent: 'space-around',
          transition: 'opacity .3s',
          borderRadius: '0 25px 25px 0',
        }}
      >
        <div
          style={{
            lineHeight: 3,
            color: 'black',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              lineHeight: '30px',
              fontSize: 14,
              letterSpacing: '1px',
            }}
          >
            <span style={{ fontFamily: 'Impact, lantin' }}>
              {portfolio[10]}
            </span>
            <span style={{ fontFamily: 'Impact, lantin' }}>{portfolio[9]}</span>
            <span>
              <pre style={{ fontFamily: 'lantin' }}>{portfolio[5]}</pre>
            </span>
          </div>
          <div
            style={{
              fontFamily: 'impact',
              border: '1px solid black',
              width: 140,
              height: 33,
              margin: '0 auto',
              marginTop: 60,
              letterSpacing: '2px',
              cursor: 'pointer',
              fontSize: 18,
              lineHeight: '33px',
            }}
            onClick={() => {
              let target = ''
              if (portfolio[3] === '影片') target = 'video'
              if (portfolio[3] === '音訊') target = 'audio'
              if (portfolio[3] === '平面設計') target = 'graphic'
              history.push(`/departments/${target}/${portfolio[0]}`)
            }}
          >
            LEARN MORE
          </div>
        </div>
      </div>
      <img
        style={{
          flex: 1,
          borderRadius: 25,
        }}
        width="400"
        height="100%"
        src={portfolio[12]}
        alt="portfoilo-2"
      />
      <div
        style={{
          flex: 1,
        }}
      >
        <div
          style={{
            fontFamily: 'lantin',
            letterSpacing: 3,
            marginLeft: 20,
            cursor: 'pointer',
          }}
          className="title"
          onClick={() => {
            let target = ''
            if (portfolio[3] === '影片') target = 'video'
            if (portfolio[3] === '音訊') target = 'audio'
            if (portfolio[3] === '平面設計') target = 'graphic'
            history.push(`/departments/${target}/${portfolio[0]}`)
          }}
        >
          {portfolio[0]}
        </div>
        <div
          style={{
            fontFamily: 'source-han-serif-tc',
            fontStyle: 'none',
            fontWeight: 700,
            lineHeight: 2,
            fontSize: 16,
            width: 420,
            textAlign: 'justify',
            marginTop: 20,
            marginLeft: 20,
          }}
          className="description"
        >
          {portfolio[1]}
        </div>
      </div>
    </div>
  )
}
function Portfolio() {
  const [portfolios, setPortfolios] = useRecoilState(portfolioState)
  const [scroll, setScroll] = useRecoilState(scrollState)
  const [selectCategory, setSelectCategory] = React.useState('')
  const [filterText, setFilterText] = React.useState('')
  const ScrollWrapper = React.useRef(null)
  const location = useLocation()
  const history = useHistory()
  const handleScroll = (e) => {
    setScroll({
      y: e.target.scrollTop,
    })
  }
  React.useEffect(() => {
    ScrollWrapper.current.addEventListener('scroll', handleScroll)
    return () => {
      ScrollWrapper.current.removeEventListener('scroll', handleScroll)
    }
  }, [])
  React.useEffect(() => {
    axios({
      method: 'GET',
      url:
        'https://script.google.com/macros/s/AKfycbzSt-3g7sEZZch35TreSZo_c1kplgDKsKde9Sx_pS3BmeNt6KU/exec?sheet=1',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((r) => {
      const data = r.data
      setPortfolios(data)
    })
    setSelectCategory(location.hash.replace('#', '').toLocaleLowerCase())
  }, [])
  React.useEffect(() => {
    ScrollWrapper.current.scrollTo(0, scroll.y)
  }, [scroll.y])
  return (
    <HackWrapper>
      <Wrapper ref={ScrollWrapper}>
        <Cover>
          <img
            onClick={() => history.push('/')}
            onMouseEnter={(e) => {
              e.target.src = TitleImgPink
            }}
            onMouseOut={(e) => {
              e.target.src = TitleImg
            }}
            src={TitleImg}
            alt="title-img"
          />
        </Cover>
        <Category>
          <Items>
            <CategoryCanvas
              selectCategory={selectCategory.toLocaleLowerCase() === 'graphic'}
              label="Graphic"
              setSelectCategory={setSelectCategory}
              portfolios={portfolios}
            />
            <CategoryCanvas
              selectCategory={selectCategory.toLocaleLowerCase() === 'audio'}
              label="Audio"
              setSelectCategory={setSelectCategory}
              portfolios={portfolios}
            />
            <CategoryCanvas
              selectCategory={selectCategory.toLocaleLowerCase() === 'video'}
              label="Video"
              setSelectCategory={setSelectCategory}
              portfolios={portfolios}
            />
          </Items>
          <Search className="fa">
            <input
              type="text"
              value={filterText}
              onChange={(e) => {
                setFilterText(e.target.value)
              }}
            />
          </Search>
        </Category>
        <Portfolios>
          {portfolios.length ? (
            portfolios
              .filter((portfolio, idx) => {
                if (idx && selectCategory === 'video') {
                  return portfolio[3] === '影片'
                }
                if (idx && selectCategory === 'audio') {
                  return portfolio[3] === '音訊'
                }
                if (idx && selectCategory === 'graphic') {
                  return portfolio[3] === '平面設計'
                }
                return portfolio
              })
              .filter((portfolio) => {
                if (filterText) {
                  return (
                    portfolio[0].toLocaleLowerCase().indexOf(filterText) >= 0
                  )
                }
                return portfolio
              })
              .map((portfolio, idx) => {
                if (portfolio[4] === '是') {
                  return <PortfolioItem key={idx} portfolio={portfolio} />
                }
                return null
              })
          ) : (
            <BeatLoader css={override} size={30} color={'#fff'} loading />
          )}
        </Portfolios>
        {portfolios.length && <Footer />}
      </Wrapper>
    </HackWrapper>
  )
}

export default Portfolio

const override = css`
  display: flex;
  margin: 0 auto;
  border-color: red;
  justify-content: center;
`

const HackWrapper = styled.div`
  height: 100vh;
`

const Wrapper = styled.div`
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-image: url(https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/BG2.jpg);
  background-position: top center;
  background-size: cover;
  min-width: 1280px;
`
const Cover = styled.div`
  padding: 70px 150px 0px 150px;
  text-align: center;

  img {
    cursor: pointer;
    width: 80%;
  }
`
const Category = styled.div`
  display: grid;
  grid-template-columns: 65% 1fr;
  justify-content: space-around;
  padding: 40px 80px 0px 80px;
`
const Items = styled.div`
  display: flex;
  justify-content: space-around;
  -webkit-text-stroke: 1px rgb(164, 153, 77);
  -webkit-text-fill-color: transparent;

  > div:hover {
    cursor: pointer;
    -webkit-text-stroke: 1px pink;
    transition: all 0.32s;
  }
`
const Search = styled.div`
  position: relative;
  display: flex;
  justify-self: end;
  align-items: center;

  :after {
    position: absolute;
    right: 10px;
    display: block;
    content: '\f002';
    font-size: 20px;
    color: white;
    cursor: pointer;
  }

  > input {
    height: 30px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    padding: 0px 35px 0px 10px;
    color: white;
  }
`
const Portfolios = styled.div`
  padding: 40px 80px 0px 80px;

  > img {
    border-radius: 10px;
  }

  > div {
    display: flex;
    color: white;
    margin-bottom: 30px;

    > div {
      padding: 0px 10px 0px 20px;
    }
  }

  .title {
    margin-top: 20px;
    font-size: 35px;
    font-weight: 900;
  }
`
