import React from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'
import BeatLoader from 'react-spinners/BeatLoader'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { portfolioState, scrollState } from '../../atoms/recoil-atoms'
import PortfolioSideBar from '../../components/PortfolioSideBar'
import HamburgerM from '../../components/HamburgerM'
import Footer from '../../components/Footer'

import TitleImg from '../../images/標題.png'

function PortfolioItem({ idx, portfolio, isOpen, onClick }) {
  // const [openInfo, setOpenInfo] = React.useState(false)
  const history = useHistory()
  // const handleOpenInfo = () => {
  //   if (openInfo) {
  //     setOpenInfo(false)
  //   } else {
  //     setOpenInfo(true)
  //   }
  // }
  console.log(isOpen)
  return (
    <Item key={idx}>
      <img src={portfolio[12]} alt="portfoilo-2" onClick={onClick} />
      {isOpen ? (
        <Info>
          <div>{portfolio[0]}</div>
          <span style={{ fontFamily: 'lantin' }}>{portfolio[10]}</span>｜
          <span style={{ fontFamily: 'impact, lantin' }}>{portfolio[9]}</span>｜
          <span style={{ fontFamily: 'lantin' }}>{portfolio[5]}</span>
          <div
            style={{
              fontFamily: 'impact',
              border: '1px solid black',
              width: 120,
              height: 25,
              margin: '20px auto',
              letterSpacing: '2px',
              cursor: 'pointer',
              fontSize: 16,
              lineHeight: '25px',
              textAlign: 'center',
            }}
            onClick={() => {
              let target = ''
              if (portfolio[3] === '影片') target = 'video'
              if (portfolio[3] === '音訊') target = 'audio'
              if (portfolio[3] === '平面設計') target = 'graphic'
              history.push(`/departments/${target}/${portfolio[0]}`)
            }}
          >
            LEARN MORE
          </div>
        </Info>
      ) : null}
    </Item>
  )
}

function Portfolio() {
  const [selectCategory, setSelectCategory] = React.useState('')
  const [openInfoIndex, setOpenInfoIndex] = React.useState(null)
  const [portfolios, setPortfolios] = useRecoilState(portfolioState)
  const location = useLocation()
  const ScrollWrapper = React.useRef(null)
  React.useEffect(() => {
    axios({
      method: 'GET',
      url:
        'https://script.google.com/macros/s/AKfycbzSt-3g7sEZZch35TreSZo_c1kplgDKsKde9Sx_pS3BmeNt6KU/exec?sheet=1',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((r) => {
      const data = r.data
      setPortfolios(data)
    })
    setSelectCategory(location.hash.replace('#', '').toLocaleLowerCase())
  }, [])
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <React.Fragment>
      <StyleWrapper ref={ScrollWrapper}>
        <Header>
          <BackButton to="/">
            <img
              style={{ width: 65, marginTop: 15 }}
              src="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/back1.png"
            />
          </BackButton>
          <img src={TitleImg} />
          <HamburgerM>
            <PortfolioSideBar
              setSelectCategory={setSelectCategory}
              selectCategory={selectCategory}
            />
          </HamburgerM>
        </Header>
        <List>
          {portfolios.length ? (
            portfolios
              .filter((portfolio, idx) => {
                if (idx && selectCategory === 'all') {
                  return portfolio
                }
                if (idx && selectCategory === 'video') {
                  return portfolio[3] === '影片'
                }
                if (idx && selectCategory === 'audio') {
                  return portfolio[3] === '音訊'
                }
                if (idx && selectCategory === 'graphic') {
                  return portfolio[3] === '平面設計'
                }
                return portfolio
              })
              .map((portfolio, idx) => {
                if (!idx) return
                return (
                  <PortfolioItem
                    onClick={() => {
                      if (openInfoIndex === idx) {
                        setOpenInfoIndex(null)
                      } else {
                        setOpenInfoIndex(idx)
                      }
                    }}
                    isOpen={openInfoIndex === idx}
                    key={idx}
                    idx={idx}
                    portfolio={portfolio}
                  />
                )
              })
          ) : (
            <BeatLoader css={override} size={30} color={'#fff'} loading />
          )}
          {portfolios.length ? <Footer /> : null}
        </List>
      </StyleWrapper>
    </React.Fragment>
  )
}

export default Portfolio

const override = css`
  display: flex;
  margin: 0 auto;
  border-color: red;
  justify-content: center;
`
const StyleWrapper = styled.div`
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-image: url(https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/BG2.jpg);
  background-position: top center;
  background-size: cover;
  padding: 10px 0px 10px 0px;
`
const Header = styled.div`
  position: sticky;
  height: 80px;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  > img {
    position: absolute;
    width: 50%;
    left: 50%;
    transform: translate(-50%, 15px);
  }
`
const BackButton = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 50px;
  height: 40px;
  color: #fff;
  left: 14px;
  top: 5px;
  & i {
    font-size: 20px;
  }
  & span {
    font-size: 16px;
    font-weight: 700;
  }
`
const List = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`
const Item = styled.div`
  z-index: 0;
  border-radius: 5px;
  position: relative;
  padding: 0px 15px 0px 15px;
  > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`
const Info = styled.div`
  position: absolute;
  z-index: 99;
  bottom: 0;
  height: 50%;
  width: calc(100% - 30px);
  border-radius: 0 0 5px 5px;
  background-color: rgba(238, 224, 112, 0.7);
  padding: 10px;
  overflow-y: scroll;

  > div {
    font-family: lantin;
    font-size: 20px;
  }

  > span {
    font-size: 12px;
  }
`
