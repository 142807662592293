import { scroller, Events } from 'react-scroll'

class PageScrollingControl {
  constructor(args) {
    this.pageIds = args.pageIds
    this._page = args.page || 0
    Events.scrollEvent.register('end', function (to, element) {
      // if (to) window.location.hash = to
    })
  }
  _pageScrollTo() {
    scroller.scrollTo(this.pageIds[this.page], {
      duration: 320,
      delay: 0,
      smooth: 'linear',
    })
  }
  pageUp(page) {
    let newPage = page - 1
    if (newPage < 0) {
      newPage = 0
    }
    this._page = newPage
    this._pageScrollTo()
  }
  pageDown(page) {
    let newPage = page + 1
    if (newPage >= this.pageIds.length) {
      newPage = page
    }
    this._page = newPage
    this._pageScrollTo()
  }
  pageToTop() {
    scroller.scrollTo(this.pageIds[0], {
      duration: 320,
      delay: 0,
      smooth: 'linear',
    })
  }
  set page(value) {
    const page = this.pageIds.indexOf(value)
    this._page = page
  }
  get page() {
    return this._page
  }
}

export default PageScrollingControl
