import React from 'react'
import styled from 'styled-components'
import 'react-h5-audio-player/lib/styles.css'
import Modal from 'react-modal'
import { useRecoilState } from 'recoil'
import CancelImg from '../../images/Ｘ.png'

import {
  teacherInfoModalIsOpenState,
  teacherInfoModalDataState,
} from '../../atoms/recoil-atoms'

const MobileModalStyles = {
  overlay: { zIndex: 3 },
  content: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
}

function TeacherInfoModal(props) {
  const [isOpen, setIsOpen] = useRecoilState(teacherInfoModalIsOpenState)
  const [teacher] = useRecoilState(teacherInfoModalDataState)
  const wrapperRef = React.useRef()
  const closeInfoModal = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      style={MobileModalStyles}
      isOpen={isOpen}
      onRequestClose={closeInfoModal}
    >
      <MobileTitle>{teacher.name}</MobileTitle>
      <div
        style={{
          marginTop: 10,
        }}
      >
        <img style={{ width: '100%' }} src={teacher.pic} />
        <div
          onClick={closeInfoModal}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <img width={60} height={60} src={CancelImg} alt="cancel-img" />
        </div>
      </div>
      <div>
        <MobileBottomSide>
          {teacher.title.map((t) => {
            return <div style={{ textAlign: 'center' }}>{t}</div>
          })}
          <div>
            <MobileDescription>{teacher.description}</MobileDescription>
          </div>
        </MobileBottomSide>
      </div>
    </Modal>
  )
}

export default TeacherInfoModal

const MobileBottomSide = styled.div`
  height: 300px;
`
const MobileTitle = styled.div`
  font-family: ${(props) =>
    props.title === '黃嬿蓁' ||
    props.title === '簡莞錤' ||
    props.title === '朱瑄'
      ? 'source-han-serif-tc'
      : 'CSong3HK'};
  font-weight: 700;
  font-style: none;
  font-size: 24px;
  color: #747574;
  text-align: center;
  margin-top: 15px;
`
const MobileDescription = styled.div`
  margin: 0 auto;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
  font-family: 'Microsoft JhengHei';
  width: 100%;
  margin: 0 auto;
  color: #969796;
  text-align: justify;
  padding: 15px;
`
