import React from 'react'
import styled from 'styled-components'

function Teacher({ imgSrc, description, name, major }) {
  return (
    <StyleWrapper>
      <TeacherImg src={imgSrc} />
      <Description>{description}</Description>
      <Name>{name}</Name>
      <Major>{major}</Major>
    </StyleWrapper>
  )
}

export default Teacher

const StyleWrapper = styled.div`
  position: relative;
  max-width: 1336px;
  min-width: 100vw;
  width: 100vw;
  height: 100vh;
  font-family: 'source-han-serif-tc';
  font-style: none;
`
const Description = styled.div`
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 18px;
  line-height: 36px;
  position: absolute;
  left: 20%;
  bottom: 0px;
  transform: translate(400px, -250px);
  font-weight: 400;

  @media (max-width: 1200px) {
    left: 0%;
    font-size: 16px;
  }
  @media (max-width: 960px) {
    left: 0%;
    font-size: 14px;
  }
`
const Name = styled.div`
  font-size: 48px;
  line-height: 30px;
  letter-spacing: 4.8px;
  position: absolute;
  left: 20%;
  bottom: 0px;
  transform: translate(280px, -140px);
  font-weight: 700;
  @media (max-width: 1200px) {
    left: 0%;
    font-size: 44px;
  }
`
const Major = styled.div`
  text-align: center;
  letter-spacing: 1.8px;
  font-size: 18px;
  line-height: 30px;
  position: absolute;
  left: 20%;
  bottom: 0px;
  transform: translate(355px, -50px);
  font-weight: 400;
  @media (max-width: 1200px) {
    left: 0%;
    font-size: 16px;
  }
`
const TeacherImg = styled.img`
  position: absolute;
  bottom: 0px;
  left: 354px;
  @media (max-width: 1200px) {
    left: 224px;
  }
  @media (max-width: 960px) {
    left: 154px;
  }
`
