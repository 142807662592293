import React from 'react'
import styled from 'styled-components'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import Modal from 'react-modal'
import axios from 'axios'
import { isMobile } from 'react-device-detect'
import { useRecoilState, useRecoilValue } from 'recoil'
import CancelImg from '../../images/Ｘ.png'

import {
  infoModalIsOpenState,
  infoModalPersonState,
} from '../../atoms/recoil-atoms'

const MobileModalStyles = {
  overlay: { zIndex: 3 },
  content: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
}
const ModalStyles = {
  content: {
    padding: 0,
    display: 'flex',
  },
  overlay: { zIndex: 2 },
}
const innerModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 4 },
}

function InfoModal() {
  const [innerModalOpen, setInnerModalOpen] = React.useState(false)
  const [isCommenting, setIsCommenting] = React.useState(false)
  const [infoModalisOpen, setInfoModalIsOpen] = useRecoilState(
    infoModalIsOpenState
  )
  const person = useRecoilValue(infoModalPersonState)
  const [comment, setComment] = React.useState({
    commenter: '',
    comment: '',
  })
  const wrapperRef = React.useRef()
  const closeInfoModal = () => {
    setInfoModalIsOpen(false)
  }

  if (isMobile) {
    return (
      <Modal
        closeTimeoutMS={500}
        ariaHideApp={false}
        style={MobileModalStyles}
        isOpen={infoModalisOpen}
        onRequestClose={closeInfoModal}
      >
        <div
          style={{
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <MobileTitle title={person.title}>{person.title}</MobileTitle>
          <MobileComment onClick={() => setInnerModalOpen(true)}>
            <span>留言給我</span>
            <i className="far fa-comment-dots"></i>
          </MobileComment>
          <div
            onClick={closeInfoModal}
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            <img width={60} height={60} src={CancelImg} alt="cancel-img" />
          </div>
        </div>
        <div
          style={{
            overflowY: 'scroll',
          }}
        >
          <MobileTopSide>
            <img
              src={`https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images-new/${person.picture}-868x1227.png`}
            />
          </MobileTopSide>
          <MobileBottomSide>
            <div>
              <MobileDescription
                dangerouslySetInnerHTML={{
                  __html: person.description,
                }}
              />
            </div>
            <AudioPlayer
              style={{
                bottom: 0,
              }}
              autoPlay
              customAdditionalControls={[]}
              customVolumeControls={[]}
              showJumpControls
              src={`https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/audio/${
                person.title
              }.${person.type ? person.type : `mp3`}`}
              controls
            />
          </MobileBottomSide>
        </div>
        <Modal
          isOpen={innerModalOpen}
          style={innerModalStyles}
          onRequestClose={() => setInnerModalOpen(false)}
          appElement={wrapperRef.current}
        >
          <CommentForm>
            <div>留言給 {person.title}</div>
            <div className="commenter">
              <input
                type="text"
                value={comment.commenter}
                placeholder="暱稱"
                onChange={(e) => {
                  setComment({
                    ...comment,
                    commenter: e.target.value,
                  })
                }}
              />
            </div>
            <div>
              <textarea
                type="text"
                value={comment.comment}
                placeholder="想說的話"
                onChange={(e) => {
                  setComment({
                    ...comment,
                    comment: e.target.value,
                  })
                }}
              />
            </div>
            <button
              onClick={() => {
                if (comment.comment === '') return
                setIsCommenting(true)
                if (isCommenting) return
                axios({
                  method: 'GET',
                  url: `https://script.google.com/macros/s/AKfycbzSt-3g7sEZZch35TreSZo_c1kplgDKsKde9Sx_pS3BmeNt6KU/exec?method=write&commenter=${comment.commenter}&receiver=${person.title}&comment=${comment.comment}`,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }).then((r) => {
                  const data = r.data
                  if (data.status === 'OK') {
                    setInnerModalOpen(false)
                    setIsCommenting(false)
                    setComment({
                      commenter: '',
                      comment: '',
                    })
                  }
                })
              }}
            >
              {isCommenting ? '發送中' : '確認留言'}
            </button>
          </CommentForm>
        </Modal>
      </Modal>
    )
  }

  return (
    <Modal
      closeTimeoutMS={500}
      ariaHideApp={false}
      style={ModalStyles}
      isOpen={infoModalisOpen}
      onRequestClose={closeInfoModal}
    >
      <div
        onClick={closeInfoModal}
        style={{
          zIndex: 99,
          width: 60,
          height: 60,
          position: 'absolute',
          right: 0,
          top: 0,
          cursor: 'pointer',
        }}
      >
        <img width={60} height={60} src={CancelImg} alt="cancel-img" />
      </div>
      <LeftSide
        backgroundImageSrc={`https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images-new/${person.picture}-868x1227.png`}
      ></LeftSide>
      <RightSide>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Comment onClick={() => setInnerModalOpen(true)}>
            <Title title={person.title}>{person.title}</Title>
            <span style={{ cursor: 'pointer' }}>留言給我</span>
            <i className="far fa-comment-dots"></i>
          </Comment>
        </div>
        <Description
          dangerouslySetInnerHTML={{
            __html: person.description,
          }}
        />
        <AudioPlayer
          style={{
            position: 'absolute',
            bottom: 0,
          }}
          autoPlay
          showJumpControls
          src={`https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/audio/${
            person.title
          }.${person.type ? person.type : `mp3`}`}
          controls
        />
      </RightSide>
      <Modal
        isOpen={innerModalOpen}
        style={innerModalStyles}
        onRequestClose={() => setInnerModalOpen(false)}
        appElement={wrapperRef.current}
      >
        <CommentForm>
          <div>留言給 {person.title}</div>
          <div className="commenter">
            <input
              type="text"
              value={comment.commenter}
              placeholder="暱稱"
              onChange={(e) => {
                setComment({
                  ...comment,
                  commenter: e.target.value,
                })
              }}
            />
          </div>
          <div>
            <textarea
              type="text"
              value={comment.comment}
              placeholder="想說的話"
              onChange={(e) => {
                setComment({
                  ...comment,
                  comment: e.target.value,
                })
              }}
            />
          </div>
          <button
            onClick={() => {
              setIsCommenting(true)
              if (isCommenting) return
              axios({
                method: 'GET',
                url: `https://script.google.com/macros/s/AKfycbzSt-3g7sEZZch35TreSZo_c1kplgDKsKde9Sx_pS3BmeNt6KU/exec?method=write&commenter=${comment.commenter}&receiver=${person.title}&comment=${comment.comment}`,
                headers: {
                  'Content-Type': 'application/json',
                },
              }).then((r) => {
                const data = r.data
                if (data.status === 'OK') {
                  setInnerModalOpen(false)
                  setIsCommenting(false)
                  setComment({
                    commenter: '',
                    comment: '',
                  })
                }
              })
            }}
          >
            {isCommenting ? '發送中' : '確認留言'}
          </button>
        </CommentForm>
      </Modal>
    </Modal>
  )
}

export default InfoModal

const MobileTopSide = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`
const MobileBottomSide = styled.div`
  height: 300px;
`
const MobileTitle = styled.div`
  font-family: ${(props) =>
    props.title === '黃嬿蓁' ||
    props.title === '簡莞錤' ||
    props.title === '朱瑄'
      ? 'source-han-serif-tc'
      : 'CSong3HK'};
  font-weight: 700;
  font-style: none;
  font-size: 24px;
  color: #747574;
  text-align: center;
`
const MobileDescription = styled.div`
  margin: 0 auto;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
  font-family: 'Microsoft JhengHei';
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  color: #969796;
`
const MobileComment = styled.div`
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  font-family: 'Microsoft JhengHei';
  > i {
    margin-left: 10px;
  }
`

const LeftSide = styled.div`
  flex: 1;
  background-image: url(${(props) => props.backgroundImageSrc});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`
const RightSide = styled.div`
  position: relative;
  flex: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding-bottom: 60px;
`
const Description = styled.div`
  margin: 0 auto;
  font-size: 16px;
  line-height: 34px;
  font-family: 'Microsoft JhengHei';
  width: 550px;
  min-height: 300px;
  flex: 2;
  margin: 0 auto;
  overflow-y: scroll;
  color: #969796;
  margin-top: 10px;
  padding-bottom: 5%;
`
const Title = styled.div`
  font-family: ${(props) =>
    props.title === '黃嬿蓁' ||
    props.title === '簡莞錤' ||
    props.title === '朱瑄'
      ? 'source-han-serif-tc'
      : 'CSong3HK'};
  margin-bottom: ${(props) =>
    props.title === '黃嬿蓁' ||
    props.title === '簡莞錤' ||
    props.title === '朱瑄'
      ? 20
      : 0}px;
  font-weight: 700;
  font-style: none;
  font-size: 42px;
  color: #747574;
`
const Comment = styled.div`
  font-size: 20px;
  font-family: 'Microsoft JhengHei';
  > i {
    margin-left: 10px;
  }
`
const CommentForm = styled.span`
  font-family: 'Microsoft JhengHei';
  .commenter {
    margin-top: 10px;
  }
  input,
  textarea {
    display: block;
    appearance: none;
    outline: 0;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.2);
    width: 100%;
    resize: none;
    border-radius: 3px;
    padding: 10px 15px;
    margin: 0 auto 10px auto;
    display: block;
    text-align: center;
    font-size: 18px;
    color: rgb(199, 181, 156);
    transition-duration: 0.25s;
    font-weight: 300;
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
    &:focus {
      background-color: white;
      color: rgb(199, 181, 156);
    }
  }
  > button {
    display: block;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    outline: none;
    &:active {
      color: white;
      background-color: rgb(199, 181, 156);
    }
  }
`
