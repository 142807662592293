import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { isBrowser } from 'react-device-detect'
import { RecoilRoot } from 'recoil'
import App from './pages/Index/'
import MobileApp from './mobile-pages/Index'
import 'react-image-lightbox/style.css'
import './main.css'
import './instagram.css'

function Container() {
  return isBrowser ? <App /> : <MobileApp />
}

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <Container />
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
)
