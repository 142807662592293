import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'
import { isMobile } from 'react-device-detect'

function SideBar({ onClick }) {
  return (
    <SideBarContainer id="menu">
      <SideItem>
        <ScrollLink
          onClick={() => onClick('reception')}
          activeClass="active"
          to="reception"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          RECEPTION 櫃檯
        </ScrollLink>
      </SideItem>
      <SideItem>
        <ScrollLink
          onClick={() => onClick('departments')}
          activeClass="active"
          to="departments"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          DEPARTMENTS 門診
        </ScrollLink>
      </SideItem>
      <SideItem>
        <ScrollLink
          onClick={() => onClick('records')}
          activeClass="active"
          to="records"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          RECORDS 病例
        </ScrollLink>
      </SideItem>
      <SideItem>
        <ScrollLink
          onClick={() => onClick('dockers')}
          activeClass="active"
          to="dockers"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          DOCTORS 醫生
        </ScrollLink>
      </SideItem>
      <SideItem>
        <ScrollLink
          onClick={() => onClick('sponsors')}
          activeClass="active"
          to="sponsors"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
        >
          SPONSORS 贊助
        </ScrollLink>
      </SideItem>
    </SideBarContainer>
  )
}

const SideBarContainer = styled.ul`
  width: 140px;
  font-family: impact;
`
const SideItem = styled.li`
  list-style: none;
  cursor: pointer;
  margin-bottom: ${isMobile ? `25` : `20`}px;
  color: black;
  letter-spacing: 1.2px;
  & a {
    border-bottom: 0 solid red;
    padding-bottom: 8px;
  }
  & a.active {
    color: rgb(236, 200, 239);
  }
  &:hover {
    color: rgb(236, 200, 239);
  }
`
const ScrollLink = styled(Link)`
  font-family: 'impact', 'source-han-serif-tc';
  font-style: none;
  font-weight: 700;
`

export default SideBar
