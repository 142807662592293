import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import gsap from 'gsap'
import azaleaImg from '../../images/Azalea.jpg'

import IndexPage from '../IndexPage'
import Portfolio from '../Portfolio'
import Works from '../../pages/Works'

const Wrapper = styled.div`
  background-image: url(${azaleaImg});
  background-size: cover;
  opacity: 0.7;
  color: white;
  font-size: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  cursor: default;
  user-select: none;
  text-shadow: 2px 2px #000;
`

function Index() {
  const t1 = useRef(null)
  const t3 = useRef(null)
  const wrapperRef = useRef(null)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/') return
    const tl = gsap.timeline()
    tl.fromTo(
      t1.current,
      {
        transform: 'translate(0, 50px)',
        opacity: 0,
      },
      {
        duration: 1,
        transform: 'translate(0, -50px)',
        opacity: 1,
      }
    )
      .fromTo(
        t3.current,
        {
          transform: 'translate(0, 50px)',
          opacity: 0,
        },
        {
          transform: 'translate(0, 0px)',
          opacity: 1,
        }
      )
      .fromTo(
        wrapperRef.current,
        {
          filter: 'blur(8px)',
        },
        {
          duration: 1,
          filter: 'blur(0px)',
        }
      )
      .fromTo(
        t3.current,
        {
          transform: 'translate(0, 0px)',
        },
        {
          duration: 1,
          repeat: -1,
          yoyoEase: true,
          transform: 'translate(0, 5px)',
        }
      )
  }, [location.pathname])

  return (
    <TransitionWrapper>
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={location.key}
          timeout={{ enter: 300, exit: 300 }}
          classNames={'fade'}
        >
          <section className="route-section">
            <Route path="/departments" exact component={Portfolio} />
            <Route path="/" exact component={IndexPage} />
            <Route
              exact
              path="/departments(/graphic|/audio|/video)?/:name"
              component={Works}
            />
            {/* <Route path="/" exact>
              <Wrapper ref={wrapperRef}>
                <div
                  style={{
                    position: 'relative',
                    width: '100vw',
                  }}
                >
                  <div style={{ textAlign: 'center', width: '100%' }} ref={t1}>
                    國立臺灣藝術大學廣播電視學系線上展
                  </div>
                  <div style={{ textAlign: 'center', width: '100%' }} ref={t3}>
                    Coming soon ...
                  </div>
                </div>
              </Wrapper>
            </Route> */}
          </section>
        </CSSTransition>
      </TransitionGroup>
    </TransitionWrapper>
  )
}

const TransitionWrapper = styled.div`
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  div.transition-group {
    position: relative;
  }

  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
`

export default Index
