import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import TeacherImg from '../../images/tea1.png'
import TeacherLinImg from '../../images/tea2.png'

import LifeShowImg from '../../images/直播中1.png'
import LifeShowCImg from '../../images/直播中-C1.png'

import SectionPart from '../../components/SectionPart'
import SideBar from '../../components/SideBar'
import Footer from '../../components/Footer'

import PageScrollingControl from '../../utils/pageScrollingControl'

import BackgroundClip from '../../components/BackgroundClip'
import RecordButton from '../../components/RecordButton'
import InfoModal from '../../components/InfoModal'
import Hamburger from '../../components/Hamburger'
import Teacher from '../../components/Teacher'
import TeacherLin from '../../components/TeacherLin'
import SponsorList from '../../components/SponsorList'

import { device } from '../../utils/mediaQueryDevice'

import { menuState } from '../../atoms/recoil-atoms'
import data from '../../data/people.json'

const pageIDs = [
  'landing',
  'reception',
  'reception-2',
  'reception-3',
  'departments',
  'records',
  'dockers',
  'dockers-2',
  'sponsors',
]

function IndexPage() {
  const [menu, setMenuState] = useRecoilState(menuState)
  const [page, setPage] = React.useState(0)
  const [offsetY, setOffsetY] = React.useState(0)
  const [showTop, setShowTop] = React.useState(false)
  const [isOpenLive, setIsOpenLive] = React.useState(false)
  const [isLiveTime, setIsLiveTime] = React.useState(false)
  const [liveLink, setLiveLink] = React.useState('')
  const location = useLocation()
  const pageScrollingControl = React.useRef()
  React.useEffect(() => {
    axios({
      method: 'GET',
      url:
        'https://script.google.com/macros/s/AKfycbzSt-3g7sEZZch35TreSZo_c1kplgDKsKde9Sx_pS3BmeNt6KU/exec?sheet=4',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((r) => {
      const data = r.data
      if (data[1][0] === '是') {
        setIsLiveTime(true)
        setLiveLink(data[1][1])
      }
    })
  }, [])
  React.useEffect(() => {
    pageScrollingControl.current = new PageScrollingControl({
      pageIds: pageIDs,
      page: 0,
    })
    switch (location.hash) {
      case '#landing':
        return setPage(0)
      case '#reception':
        return setPage(1)
      case '#reception-2':
        return setPage(2)
      case '#reception-3':
        return setPage(3)
      case '#departments':
        return setPage(4)
      case '#records':
        return setPage(5)
      case '#doctors':
        return setPage(6)
      case '#doctors-2':
        return setPage(7)
      case '#sponsors':
        return setPage(8)
      default:
        return setPage(page)
    }
  }, [location.hash])

  return (
    <ReactScrollWheelHandler
      onMouseDown={() => {}}
      upHandler={(e) => {
        // pageScrollingControl.current.pageUp(page, setPage)
        setOffsetY(e.view.scrollY)
        setPage(pageScrollingControl.current.page)
        setShowTop(true)
      }}
      downHandler={(e) => {
        // pageScrollingControl.current.pageDown(page)
        setOffsetY(e.view.scrollY)
        setPage(pageScrollingControl.current.page)
        setShowTop(false)
      }}
    >
      <StyleContainer>
        {offsetY > 200 && showTop && (
          <ToTop
            onClick={() => {
              pageScrollingControl.current.pageToTop()
              setPage(0)
              setOffsetY(0)
            }}
          >
            Top
          </ToTop>
        )}
        <LeftSide>
          <SectionPart
            anchorId="landing"
            content={
              <React.Fragment>
                <video
                  style={{
                    objectFit: 'fill',
                    width: '100vw',
                    height: '100vh',
                  }}
                  autoPlay
                  loop
                  muted
                >
                  <source
                    src="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/video/0608notitle.mp4"
                    type="video/mp4"
                  />
                </video>
                <ScrollToDown
                  onClick={() => {
                    pageScrollingControl.current.pageDown(0)
                    setPage(0)
                  }}
                >
                  Scroll For Enter
                </ScrollToDown>
              </React.Fragment>
            }
          />
          <SectionPart
            animationTitle="緣起"
            anchorId="reception"
            desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E7%B7%A3%E8%B5%B7.png"
            content={
              <div>
                <div>起源於希臘劇作家亞里斯多分・尼茲</div>
                <div>在作品《The Birds》中提及的「雲裡杜鵑窩」，</div>
                <div>後來被解讀為幻想中的世外桃源。</div>
              </div>
            }
          />
          <SectionPart
            animationTitle="致敬"
            anchorId="reception-2"
            desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E8%87%B4%E6%95%AC.png"
            content={
              <div>
                <div>1975年美國電影《飛越杜鵑窩》</div>
                <div>描述精神病患在精神病院裡反抗體制，</div>
                <div>杜鵑窩從此代指精神病院。</div>
                <div>我們想借用精神病院比喻我們獨特性格，</div>
                <div>在他人眼裡也許覺得我們怪誕不經，</div>
                <div>但在我們的世界裡，</div>
                <div>「杜鵑窩」是讓我們肆意揮灑自我的樂園。</div>
              </div>
            }
          />
          <SectionPart
            animationTitle="築城"
            anchorId="reception-3"
            desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E7%AF%89%E5%9F%8E.png"
            content={
              <div>
                <div>我們充滿幻想和創意，</div>
                <div>腦中擁有著天馬行空的想法，</div>
                <div>有時瘋狂得如同人們眼中的精神病患。</div>
                <div>這次我們想透過創作，</div>
                <div>邀請您來一探究竟我們眼中看見的是什麼樣的世界。</div>
                <div>社會、學校的壓力像是牢籠，</div>
                <div>然而我們仍將日子，</div>
                <div>過得如同置身一座瘋狂有趣的樂園。</div>
              </div>
            }
          />
          <SectionPart anchorId="departments" content={<BackgroundClip />} />
          <SectionPart
            useTableletLStyle
            anchorId="records"
            content={
              <RecordWrapper>
                {data.map((person, idx) => {
                  return <RecordButton key={idx} person={person} idx={idx} />
                })}
              </RecordWrapper>
            }
          />
          <SectionPart
            anchorId="dockers"
            desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E4%B8%BB%E6%B2%BB.png"
            content={
              <Teacher
                imgSrc={TeacherImg}
                description={
                  <React.Fragment>
                    <p>
                      啟明老師在這座瘋狂絢爛的杜鵑窩裡，扮演著大家長的角色。
                    </p>
                    <p>
                      他總是用如同罐頭般的爽朗笑聲帶領我們進行激烈的腦部運動。
                    </p>
                    <p>輕鬆詼諧的教導中，蘊含的是啟明老師對於專業領域的底蘊</p>
                    <p>
                      以及春風化雨慈悲心腸，彷彿什麼拐瓜劣棗經過他的巧手改造後，
                    </p>
                    <p>都能變成可以擺在微風超市販售的上等好貨，</p>
                    <p>Got it ?</p>
                  </React.Fragment>
                }
                name={
                  <React.Fragment>
                    <p>指導老師&nbsp;邱啓明</p>
                  </React.Fragment>
                }
                major={
                  <React.Fragment>
                    <p>英國卡地夫大學新聞媒體與文化研究所博士</p>
                    <p>媒體與文化研究／視覺創作與專題研究／電影研究</p>
                  </React.Fragment>
                }
              />
            }
          />
          <SectionPart
            anchorId="dockers-2"
            desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E4%B8%BB%E6%B2%BB.png"
            content={
              <TeacherLin
                imgSrc={TeacherLinImg}
                description={
                  <React.Fragment>
                    <p>每週四傍晚，一樣的緊張，一樣的期待，</p>
                    <p>究竟…明天的討論順序是……?</p>
                    <p>靖霖老師在杜鵑窩是鐵血教練般的存在，</p>
                    <p>如果你在這裡聽見了少男少女的哭聲，是誰害的呢？</p>
                    <p>別懷疑，是靖霖：））</p>
                    <p>雖然靖霖老師很硬派，但是還是深受窩民的愛戴，</p>
                    <p>該指正的地方指正，該稱讚的地方也絕不吝嗇。</p>
                    <p>
                      相信經過靖霖老師諄諄教誨後，杜鵑窩出品，絕對是品質保證。
                    </p>
                    <p>最後…我只想弱弱的問一句：老師，我能畢業了嗎？</p>
                  </React.Fragment>
                }
                name={
                  <React.Fragment>
                    <p>指導老師&nbsp;陳靖霖</p>
                  </React.Fragment>
                }
                major={
                  <React.Fragment>
                    <p>北京大學新聞與傳播學院博士</p>
                    <p>電視製作／電影產業研究／國際傳播研究</p>
                  </React.Fragment>
                }
              />
            }
          />
          <SectionPart
            anchorId="sponsors"
            desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E8%B4%8A%E5%8A%A9.png"
            content={
              <React.Fragment>
                <SponsorList
                  items={[
                    {
                      src: 'https://i.imgur.com/QVdQckU.png',
                      link: 'https://www.taipeiff.taipei/',
                    },
                    {
                      src: 'https://i.imgur.com/dqJkEws.png',
                      link: 'https://www.mgrstore.net/',
                    },
                    {
                      src: 'https://i.imgur.com/Ly73sY2.png',
                      link: 'https://www.cts.com.tw',
                    },
                  ]}
                  top={0}
                  left={-50}
                />
                <SponsorList
                  items={[
                    {
                      src: 'https://i.imgur.com/BDb8zU4.png',
                      link: 'https://www.facebook.com/DoubleNiceee/',
                    },
                    {
                      src: 'https://i.imgur.com/vKiFkJ5.png',
                      link: 'https://www.kkbox.com/tw/tc/',
                    },
                    {
                      src: 'https://i.imgur.com/wh1cZgQ.png',
                      link: 'https://www.showtimes.com.tw/',
                    },
                  ]}
                  top={120}
                  left={0}
                />
                <SponsorList
                  items={[
                    {
                      src: 'https://i.imgur.com/citNc0f.png',
                      link: 'https://www.tstartel.com/CWS/',
                    },
                    {
                      src: 'https://i.imgur.com/RP8XqWJ.png',
                      link: 'https://www.4gtv.tv/',
                    },
                    {
                      src: 'https://i.imgur.com/EfnMSbp.png',
                      link: 'https://www.facebook.com/nancyno6apt/',
                    },
                  ]}
                  top={240}
                  left={50}
                />
                <SponsorList
                  items={[
                    {
                      src: 'https://i.imgur.com/jE9u2SU.png',
                      link: 'https://salary.tw',
                    },
                    {
                      src: 'https://i.imgur.com/wSeJ6Pf.png',
                      link: 'https://interview.tw/',
                    },
                    {
                      src: 'https://i.imgur.com/KUBVMej.png',
                      link: 'https://lin.ee/Um8ccPf/wttw',
                    },
                  ]}
                  top={360}
                  left={100}
                />
                <SponsorText top={450} left={110}>
                  <a href="https://rtv.ntua.edu.tw" target="_blank">
                    國立臺灣藝術大學廣播電視學系
                  </a>
                </SponsorText>
                <SponsorText top={530} left={200}>
                  <a
                    href="http://ntuaalu.ntua.edu.tw/mp.asp?mp=9"
                    target="_blank"
                  >
                    國立臺灣藝術大學廣播電視學系&nbsp;系友會
                  </a>
                </SponsorText>
              </React.Fragment>
            }
          />
        </LeftSide>
        <Hamburger>
          <SideBar
            onClick={(id) => {
              pageScrollingControl.current.page = id
              setPage(pageScrollingControl.current.page)
              setMenuState({
                ...menu,
                isOpen: false,
              })
            }}
          />
        </Hamburger>
        <InfoModal />
        {isLiveTime && (
          <a href={liveLink}>
            <LifeShow>
              <img
                src={LifeShowImg}
                onClick={(e) => {
                  if (isOpenLive) {
                    e.target.src = LifeShowCImg
                    setIsOpenLive(false)
                  } else {
                    e.target.src = LifeShowImg
                    setIsOpenLive(true)
                  }
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  left: 120,
                  top: 90,
                }}
              >
                {isOpenLive && (
                  <div
                    style={{ width: 300, height: 250, cursor: 'pointer' }}
                  ></div>
                )}
              </div>
            </LifeShow>
          </a>
        )}
      </StyleContainer>
      <Footer />
    </ReactScrollWheelHandler>
  )
}

const StyleContainer = styled.div`
  display: flex;
  width: 100%;
  background-image: url(https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/BG1.jpg);
  background-position: center;
  background-size: 100% 100%;
`

const ScrollToDown = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -20px);
  padding: 0px 10px 0px 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
  font-family: Impact;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
`

const LeftSide = styled.div`
  width: 100%;
`

const ToTop = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: black;
  color: white;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  border-radius: 50px;
  opacity: 0.3;
  transition: opacity 0.3s;
  z-index: 2;
  font-family: Impact;

  &:hover {
    opacity: 1;
  }
`
const LifeShow = styled.div`
  position: fixed;
  bottom: 100px;
  right: 0;
  > img {
    height: 200px;
    cursor: pointer;
  }
`

const SponsorText = styled.div`
  position: absolute;
  left: 50%;
  font-size: 24px;
  letter-spacing: 3.6px;
  top: ${(props) => props.top}px;
  transform: translate(calc(-50% + ${(props) => props.left}px), 120px);

  a {
    text-decoration: none;
    color: black;
  }
`

const RecordWrapper = styled.div`
  display: grid;
  margin: 0 auto;
  @media only screen and ${device.mobileL} {
    grid-template-columns: repeat(6, 1fr);
    row-gap: 20px;
  }
  @media only screen and ${device.tablet} {
    grid-template-columns: repeat(10, 1fr);
    row-gap: 20px;
  }
  @media only screen and ${device.laptop} {
    grid-template-columns: repeat(10, 1fr);
    row-gap: 20px;
  }
  @media only screen and ${device.laptopL} {
    grid-template-columns: repeat(10, 110px);
    row-gap: 20px;
  }
`

export default IndexPage
