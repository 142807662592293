import React from 'react'
import { get } from 'lodash'
import ShareIcon from '../../images/share.svg'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import facebookW from '../../images/facebookW.png'
import facebookB from '../../images/facebookB.png'
import instagramW from '../../images/instagramW.png'
import instagramB from '../../images/instagramB.png'
import youtubeW from '../../images/youtubeW.png'
import youtubeB from '../../images/youtubeB.png'
import shareW from '../../images/shareW.png'
import shareB from '../../images/shareB.png'

function SocialLink({
  teamLink = { fb: '', ig: '', yt: '', share: '' },
  name,
  content,
}) {
  const { fb, ig, yt } = teamLink
  const type = get(content, 'source', '')
    ? Object.keys(get(content, 'source', ''))[1]
    : ''
  // console.log('type', type)
  return (
    <StyleBox>
      <StyleLink onClick={() => window.open(fb, '_blank')}>
        <StyleSocialIcon
          src={
            // ['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(name)
            //   ? facebookB
            // :
            facebookW
          }
          // color={['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(
          //   name
          // )}
        ></StyleSocialIcon>
      </StyleLink>
      <StyleLink onClick={() => window.open(ig, '_blank')}>
        <StyleSocialIcon
          src={
            // ['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(name)
            //   ? instagramB
            //   :
            instagramW
          }
          // color={['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(
          //   name
          // )}
        ></StyleSocialIcon>
      </StyleLink>
      <StyleLink onClick={() => window.open(yt, '_blank')}>
        <StyleSocialIcon
          src={
            // ['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(name)
            //   ? youtubeB
            //   :
            youtubeW
          }
          // color={['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(
          //   name
          // )}
        ></StyleSocialIcon>
      </StyleLink>
      <StyleLink
        onClick={() =>
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fntuartv105ge.com.tw%2F/departments/${type}/${name}`,
            '_blank'
          )
        }
      >
        <StyleSocialIcon
          src={
            // ['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(name)
            //   ? shareB
            //   :
            shareW
          }
          alt="share"
          // color={['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(
          //   name
          // )}
        />
      </StyleLink>
    </StyleBox>
  )
}

export default SocialLink

const StyleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 200px;
  align-items: center;
`
const StyleLink = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
`

const StyleSocialIcon = styled.div`
  width: 30px;
  height: 30px;
  background: ${(props) => `url("${props.src}")`};
  background-size: contain;
  background-position: center;
`

// const StyleShareIcon = styled.img`
//   flex: 1 1 0;
//   height: 30px;
//   width: 30px;
//   filter: ${props =>
//     props.color
//       ? `invert(99%) sepia(2%) saturate(5154%) hue-rotate(280deg)
//   brightness(118%) contrast(100%)`
//       : `invert(99%) sepia(2%) saturate(5154%) hue-rotate(280deg)
//   brightness(118%) contrast(100%)`};
// `
