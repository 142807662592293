import React, { useState } from 'react'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox'
import Carousel from 'nuka-carousel'
import { isBrowser, isMobile } from 'react-device-detect'

function WorksInfo({ teamInfo = {},pageName='' }) {
  const { name = '', type = '', videoLength = '', gallery = [] } = teamInfo
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  // console.log('name',name)
  return (
    <Wrap name={pageName}>
      <InfoBox>
        <InfoItem>
          <TextTitle>副作用</TextTitle>
          <Text>{name}</Text>
        </InfoItem>
        <InfoItem>
          <TextTitle>類型</TextTitle>
          <Text>{type}</Text>
        </InfoItem>
        <InfoItem>
          <TextTitle>作品長度</TextTitle>
          <Text>{videoLength}</Text>
        </InfoItem>
      </InfoBox>
      {isBrowser && (
        <Gallery>
          <CarouselWrap>
            <Carousel
              autoplay
              enableKeyboardControls
              defaultControlsConfig={{
                nextButtonClassName: 'fas fa-angle-right custom-button',
                nextButtonStyle: {},
                nextButtonText: ' ',
                prevButtonClassName: 'fas fa-angle-left custom-button',
                prevButtonStyle: {},
                prevButtonText: ' ',
                pagingDotsContainerClassName: '',
                pagingDotsClassName: '',
                pagingDotsStyle: { display: 'none' }
              }}
            >
              {gallery.length > 0 &&
                gallery.map((image, idx) => (
                  <img
                    src={image}
                    key={image[idx]}
                    alt="作品"
                    onClick={() => {
                      setPhotoIndex(idx)
                      setIsOpen(true)
                    }}
                  />
                ))}
            </Carousel>
            {isOpen && (
              <Lightbox
                mainSrc={gallery[photoIndex]}
                nextSrc={gallery[(photoIndex + 1) % gallery.length]}
                prevSrc={
                  gallery[(photoIndex + gallery.length - 1) % gallery.length]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + gallery.length - 1) % gallery.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % gallery.length)
                }
              />
            )}
          </CarouselWrap>
        </Gallery>
      )}
    </Wrap>
  )
}

export default WorksInfo

const Wrap = styled.div`
  width: 100%;
  height: ${isBrowser ? `100%` : `unset`};
  padding-bottom: ${isBrowser ? `40px` : `0px`};
  ${isMobile && `margin-bottom: 80px`};
  ${props=>[`個人訓練師Coach Brian行銷企劃`].includes(props.name) && isMobile ? `margin-top: 40px` :`margin-top: 10px`};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const InfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 0 0 30px;
  ${isMobile &&
    `
    padding: 0 18%;
    flex-direction: row;
    text-align: center;
    font-size: 14px;
  `}
`

const InfoItem = styled.div`
  width: 100%;
  ${isBrowser &&
    `
  &:not(:last-child) {
    margin-bottom: 40px;
  }`}
`

const TextTitle = styled.p`
  font-size: 20px;
  font-family: 'lantin';
  font-weight: 100;
  ${isMobile && `font-size: 14px`};
`

const Text = styled.p`
  font-size: 16px;
  ${isMobile && `font-size: 12px`};
`

const Gallery = styled.div`
  position: relative;
  margin-top: auto;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  padding-top: calc(100% * 9 / 16);
  & .custom-button {
    background: transparent !important;
    font-size: 30px;
    outline: none;
  }
`

const CarouselWrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  & .slider {
    height: 100% !important;
  }
  & .slider-control-centerleft {
    top: 56.5% !important;
    /* transform:translateY(-25%) !important; */
  }
  & .slider-control-centerright {
    top: 56.5% !important;
    /* transform:translateY(-25%) !important; */
  }
  & .slider-slide > img {
    max-width: 100%;
    height: auto;
  }
`
