import React from 'react'
import styled, { keyframes } from 'styled-components'
import { isBrowser } from 'react-device-detect'
import { useRecoilState } from 'recoil'

import { device } from '../../utils/mediaQueryDevice'
import {
  infoModalIsOpenState,
  infoModalPersonIndexState,
} from '../../atoms/recoil-atoms'

function RecordButton({ person, idx }) {
  const [, setInfoModalIsOpen] = useRecoilState(infoModalIsOpenState)
  const [, setInfoModalPersonIndex] = useRecoilState(infoModalPersonIndexState)
  const openModal = React.useCallback(() => {
    setInfoModalIsOpen(true)
    setInfoModalPersonIndex(idx)
  }, [idx, setInfoModalIsOpen, setInfoModalPersonIndex])
  return (
    <StyleWrapper onClick={openModal} idx={idx}>
      <img
        alt={`${person.title}-個人照片`}
        src={`https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images-new/${person.picture}-90x135.png`}
      />
    </StyleWrapper>
  )
}

const fadeIn = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
`

const StyleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 120px;
  width: 90px;
  opacity: 0;
  background-color: transparent;
  animation: ${fadeIn} 1s linear;
  animation-delay: ${(props) => props.idx * 0.03}s;
  animation-fill-mode: forwards;
  > img {
    width: 100%;
    height: 100%;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    ${(props) =>
      props.idx > 9 && props.idx < 20 && isBrowser ? 'margin-left: 120px' : ''};
    ${(props) =>
      props.idx > 29 && props.idx < 40 && isBrowser
        ? 'margin-left: 120px'
        : ''};
    transition: transform 0.3s;
    border-radius: 3px;

    &:hover {
      ${isBrowser ? `transform: scale(1.2)` : ``};
    }
  }

  @media only screen and ${device.mobileL} {
    width: 40px;
    min-height: 90px;
    margin-left: 20px;
    img {
      margin-left: 0px;
      &:hover {
        ${isBrowser ? `transform: scale(1.2)` : ``};
      }
    }
  }
  @media only screen and ${device.tablet} {
    width: calc(100% / 1.5);
    margin: 0 auto;
    img {
      &:hover {
        ${isBrowser ? `transform: scale(1.2)` : ``};
      }
      ${(props) =>
        props.idx > 9 && props.idx < 20 && isBrowser
          ? 'margin-left: 30px'
          : ''};
      ${(props) =>
        props.idx > 29 && props.idx < 40 && isBrowser
          ? 'margin-left: 30px'
          : ''};
    }
  }
  @media only screen and ${device.laptop} {
    // width: calc(100% / 1.2);
    img {
      // transform: scale(0.8);
      &:hover {
        ${isBrowser ? `transform: scale(1)` : ``};
      }
      ${(props) =>
        props.idx > 9 && props.idx < 20 && isBrowser
          ? 'margin-left: 30px'
          : ''};
      ${(props) =>
        props.idx > 29 && props.idx < 40 && isBrowser
          ? 'margin-left: 30px'
          : ''};
    }
  }
  @media only screen and ${device.laptopL} {
    img {
      width: calc(100% / 1.1);
      transform: scale(1);
      height: 120px;
      width: 90px;
      &:hover {
        ${isBrowser ? `transform: scale(1.2)` : ``};
      }
    }
  }
`

export default RecordButton
