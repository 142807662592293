/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { isBrowser, isMobile } from 'react-device-detect'
import SocialLink from '../SocialLink'
import WorksInfo from '../WorksInfo'
import backW from '../../images/backW.png'
import backB from '../../images/backB.png'

export default function WorksSection({
  type,
  link,
  info,
  children,
  content,
  name,
  id,
}) {
  if (type === 'intro') {
    return (
      <Section id="intro">
        {isBrowser && (
          <LeftSide>
            <BackButton
              src={
                // ['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(name)
                //   ? backB
                //   :
                backW
              }
              to="/departments"
              // color={['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(
              //   name
              // )}
            >
              {/* <i className="fas fa-arrow-left"></i>
              <span>BACK</span> */}
            </BackButton>
            <SocialLink teamLink={link} name={name} content={content} />
          </LeftSide>
        )}
        <Content name={name}>
          <Title>
            <TitleImg
              isOverWidth={[
                `輪到我來 It's My Turn!`,
                `個人訓練師Coach Brian行銷企劃`,
              ].includes(name)}
              isToTop={['Allen21'].includes(name)}
              size={name}
              src={get(content, 'title', '')}
            />
          </Title>
          {isMobile && <WorksInfo teamInfo={info} pageName={name} />}
          <div
            style={
              isBrowser
                ? {
                    paddingTop: [
                      `頭髮花白時：長照面面觀`,
                      `個人訓練師Coach Brian行銷企劃`,
                      `霓虹 霓虹`,
                      `Coordinates: Thailand`,
                      `數學講義`,
                    ].includes(name)
                      ? '76px'
                      : name === '因為有你，我變得更好'
                      ? '40px'
                      : name === '便利貼'
                      ? '40px'
                      : '24px',
                  }
                : { padding: '0 25px 25px 25px' }
            }
          >
            {get(content, 'article', []).map((item, index) => (
              <Article key={`${item}${index}`}>{item}</Article>
            ))}
          </div>
          {isBrowser && (
            <a
              href={`${get(content, 'learnMore', '')}` === '' ? '#slogan' : '#'}
              rel="noopener noreferrer"
              style={{
                position: 'relative',
                zIndex: 10,
                fontFamily: 'impact',
                border: '1px solid #fff',
                color: '#fff',
                textAlign: 'center',
                width: 140,
                height: 33,
                margin: isMobile && `0 auto`,
                marginTop: '30px',
                letterSpacing: '2px',
                cursor: 'pointer',
                fontSize: 18,
                lineHeight: '33px',
                textDecoration: 'none',
              }}
              onClick={(e) => {
                if (get(content, 'learnMore', '') !== '') {
                  e.preventDefault()
                  window.open(`${get(content, 'learnMore', '')}`, '_blank')
                }
              }}
            >
              LEARN MORE
            </a>
          )}
          <AvatarBox name={name} len={content.master.length}>
            {content.master.length > 0 &&
              content.master.map((avatar, idx) =>
                !get(avatar, 'src', null) ||
                !get(avatar, 'position', null) ||
                !get(avatar, 'principal', null) ? null : (
                  <Avatar
                    key={avatar.position[idx]}
                    len={content.master.length}
                  >
                    <AvatarImg src={get(avatar, 'src', '')} />

                    <div
                      style={{
                        position: 'absolute',
                        bottom: '20px',
                        fontSize: '14px',
                        color: '#000',
                        zIndex: 10,
                        opacity: 0,
                        transition: 'all .5s',
                      }}
                    >
                      {avatar.position}
                    </div>

                    <div
                      style={{
                        position: 'absolute',
                        bottom: '2px',
                        fontSize: '14px',
                        color: '#000',
                        zIndex: 10,
                        opacity: 0,
                        transition: 'all .5s',
                      }}
                    >
                      {avatar.principal}
                    </div>
                  </Avatar>
                )
              )}
          </AvatarBox>
          {isMobile && (
            <a
              href={`${get(content, 'learnMore', '')}` === '' ? '#slogan' : '#'}
              rel="noopener noreferrer"
              style={{
                position: 'relative',
                zIndex: 10,
                fontFamily: 'impact',
                border: [
                  '新思潮運動',
                  '便利貼',
                  '〈水族〉',
                  '光裡有你',
                ].includes(name)
                  ? '1px solid #fff'
                  : '1px solid #fff',
                color: [
                  '新思潮運動',
                  '便利貼',
                  '〈水族〉',
                  '光裡有你',
                ].includes(name)
                  ? '#fff'
                  : '#fff',
                textAlign: 'center',
                width: 130,
                height: 33,
                margin: `38px auto`,
                letterSpacing: '2px',
                cursor: 'pointer',
                fontSize: 16,
                lineHeight: '33px',
                textDecoration: 'none',
              }}
              onClick={(e) => {
                if (get(content, 'learnMore', '') !== '') {
                  e.preventDefault()
                  window.open(`${get(content, 'learnMore', '')}`, '_blank')
                }
              }}
            >
              LEARN MORE
            </a>
          )}
        </Content>
        {isBrowser && (
          <RightSide>
            <WorksInfo teamInfo={info} />
          </RightSide>
        )}
      </Section>
    )
  }
  if (type === 'slogan') {
    return (
      <Section id="slogan" isFullScreen>
        {isBrowser && (
          <LeftSide>
            <BackButton
              src={
                // ['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(name)
                //   ? backB
                //   :
                backW
              }
              to="/departments"
              // color={['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(
              //   name
              // )}
            >
              {/* <i className="fas fa-arrow-left"></i>
              <span>BACK</span> */}
            </BackButton>
            <SocialLink teamLink={link} name={name} content={content} />
          </LeftSide>
        )}
        <Content className="sloganContent" name={name}>
          <Slogan size={name}>{content && content.slogan}</Slogan>
        </Content>
        {isBrowser && (
          <RightSide>
            <WorksInfo teamInfo={info} />
          </RightSide>
        )}
      </Section>
    )
  }
  if (type === 'source') {
    return (
      <Section fullHeight>
        {isBrowser && (
          <LeftSide>
            <BackButton
              src={
                // ['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(name)
                //   ? backB
                //   :
                backW
              }
              to="/departments"
              // color={['新思潮運動', '便利貼', '〈水族〉', '光裡有你'].includes(
              //   name
              // )}
            >
              {/* <i className="fas fa-arrow-left"></i>
              <span>BACK</span> */}
            </BackButton>
            <SocialLink teamLink={link} name={name} content={content} />
          </LeftSide>
        )}
        <Content class="source" name={name}>
          {children()}
          {/* <AvatarBox className="role">
            {content.role.length > 0 &&
              content.role.map((avatar, idx) =>
                !get(avatar, 'src', null) ||
                !get(avatar, 'position', null) ||
                !get(avatar, 'principal', null) ? null : (
                  <Avatar key={avatar.position[idx]}>
                    <AvatarImg src={avatar.src} />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '20px',
                        fontSize: '14px',
                        color: '#000',
                        zIndex: 10,
                        opacity: 0,
                        transition: 'all .5s'
                      }}
                    >
                      {avatar.position}
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom:
                          get(avatar, 'principal', []).length > 4
                            ? '6px'
                            : '2px',
                        fontSize:
                          get(avatar, 'principal', []).length > 4
                            ? '6px'
                            : '14px',
                        color: '#000',
                        zIndex: 10,
                        opacity: 0,
                        transition: 'all .5s'
                      }}
                    >
                      {avatar.principal}
                    </div>
                  </Avatar>
                )
              )}
          </AvatarBox> */}
        </Content>
        {isBrowser && (
          <RightSide>
            <WorksInfo teamInfo={info} />
          </RightSide>
        )}
      </Section>
    )
  }
  return <Section>{children}</Section>
}

const Section = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: ${isBrowser ? `100vh` : `100%`};
  ${(props) => props.fullHeight && `height: 100%`};
  ${(props) => props.isFullScreen && isMobile && `height: 100vh`};
  transition: all 2s;
  font-family: 'source-han-serif-tc';
  &.graphic {
    overflow: scroll;
  }
`

const LeftSide = styled.div`
  height: 100%;
  width: 12%;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px 30px 0;
  align-items: center;
`

const BackButton = styled(Link)`
  position: relative;
  display: block;
  /* flex-direction: column;
  justify-content: space-between;
  align-items: center; */
  text-decoration: none;
  width: 100px;
  height: 40px;
  /* color: ${(props) => (props.color ? '#fff' : '#fff')}; */
  background: ${(props) => `url("${props.src}")`};
  background-size:contain;
  background-position:center;
  background-repeat:none;
  left: -12px;
  position: relative;
    display: block;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 200px;
    height: 80px;
    background-repeat: no-repeat;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:active {
    color: rgba(255, 255, 255, 0.8);
  }
  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  & i {
    font-size: 20px;
  }
  & span {
    font-size: 16px;
    font-weight: 700;
  }
`

const RightSide = styled.div`
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 100%;
  width: 24%;
  padding: 0 1px;
  border-left: 0.2px solid #fff;
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${isBrowser && `padding-top: 80px`};
  ${isBrowser && `width: 64%`};
  ${isBrowser && `margin: 0 24% 0 12%`};
  ${isMobile && `margin: 0 auto`};
  ${(props) =>
    isMobile && props.name === `輪到我來 It's My Turn!` && `padding-top: 10px;`}
  overflow-wrap: break-word;
  line-height: 2;
  &.sloganContent {
    padding-top: 0;
    /* padding-left: 50px; */
    justify-content: center;
  }
  &.source {
    width: 100%;
  }
`

const Title = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  height: 17%;
`

const TitleImg = styled.img`
  /* position: ${isBrowser && `absolute`}; */
width:${(props) =>
  [
    `輪到我來 It's My Turn!`,
    `小米的文化：禮納里`,
    `因為有你，我變得更好`,
  ].includes(props.size)
    ? '90%'
    : [`破劫`].includes(props.size)
    ? '25%'
    : [`〈水族〉`, `聲。態`, `便利貼`].includes(props.size)
    ? '30%'
    : [`弓杯蛇影`].includes(props.size)
    ? '40%'
    : '50%'};
  ${(props) =>
    isMobile && (props.size === '破劫' || props.size === '集體記憶')
      ? `width: 70%`
      : isMobile
      ? `width: 80%`
      : ''};
   
  ${isMobile && `transform: translate(15%,0);margin-top:30px;`}
  height: auto;
  vertical-align: top;
  /* ${isBrowser && `left: -8%`}; */
  /* bottom: ${(props) =>
    isBrowser
      ? props.isToTop
        ? '-130%'
        : isBrowser && [`輪到我來 It's My Turn!`].includes(props.size)
        ? '-124%;'
        : '-100%'
      : ``}; */
`

const Article = styled.p`
  font-size: ${isBrowser ? `16px` : `12px`};
  ${isBrowser && `margin: 0 20% -2px 0`};
  ${isMobile && `text-align: center`};
`

const AvatarBox = styled.div`
  bottom: ${(props) => (props.name === '集體記憶' ? '30px' : '10px')};
  position: absolute;
  /* bottom: 10px; */
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-top: auto;
  flex-wrap: wrap;
  ${(props) =>
    isMobile && props.len > 4
      ? `padding: 0px 12%;`
      : isMobile
      ? `padding: 0 18%`
      : ''};
  ${isBrowser && `padding-right: 20px`};
  &.role {
    position: ${isBrowser ? `absolute` : `block`};
    bottom: ${isBrowser ? `-300px` : `0px`};
    z-index: 20;
  }
  ${isMobile &&
  `
    position: relative;
    margin-top: 40px;
    justify-content: center;
    display:flex;
    justify-content:space-between;
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // row-gap: 30px;
  `}
  ${(props) => (isMobile && props.len < 4 ? `justify-content: center;` : ``)}
`

const Avatar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${isBrowser ? `101px` : `55px`};
  height: ${isBrowser ? `101px` : `55px`};
  overflow: hidden;
  background-position: center;
  background-size: 200%;
  ${isBrowser && `margin-right: 30px`};
  ${isBrowser && `margin-bottom: 20px`};
  ${(props) => (isMobile && props.len < 4 ? `margin:0 5px;` : '')};
  overflow: hidden;
  ${isBrowser &&
  `&:hover {
    &:after {
      opacity: 0.5;
    }
    & > div {
      opacity: 1 !important;
    }
  };`}
  &:after {
    content: '';
    color: white;
    opacity: 0;
    position: absolute;
    bottom: 0.5px;
    left: 1px;
    border: -1px solid rgb(255, 255, 153);
    width: 100px;
    height: 50px; /* as the half of the width */
    background: rgb(255, 255, 153);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom: 0;
    transition: all 0.5s;
  }
`

const AvatarImg = styled.img`
  width: 100%;
  height: auto;
`

const Slogan = styled.h2`
  font-size: ${(props) =>
    props.size === '新思潮運動' ||
    props.size === '個人訓練師Coach Brian行銷企劃'
      ? '36px'
      : props.size === '集體記憶'
      ? '50px'
      : '54px'};
  font-family: 'lantin';
  word-spacing: -2px;
  font-style: italic;
  font-weight: normal !important;
  margin-left: -5%;
  width: 100%;
  ${isMobile &&
  `
    font-size: 20px;
    margin: 0 auto;
  `};
`
