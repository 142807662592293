import React from 'react'
import styled from 'styled-components'
import { isBrowser } from 'react-device-detect'
import facebookImg from '../../images/facebook.png'
import instagramImg from '../../images/instagram.png'
import shareImg from '../../images/share.png'

function Footer(props) {
  return (
    <StyleWrapper fixed={props.fixed}>
      <FooterContent className="footer__content">
        <FooterText>國立臺灣藝術大學 廣播電視學系一Ｏ五級</FooterText>
        <FooterText>2020</FooterText>
      </FooterContent>
      {isBrowser && (
        <FooterSocialMedial>
          <a href="https://www.facebook.com/NTUARTV105/" target="_blank">
            <img src={facebookImg} />
          </a>
          <a
            href="https://www.instagram.com/ntuartv105/?igshid=m75gi1y8lha8"
            target="_blank"
          >
            <img src={instagramImg} />
          </a>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://ntuartv105ge.com.tw/"
            target="_blank"
          >
            <img style={{ marginLeft: -2 }} src={shareImg} />
          </a>
        </FooterSocialMedial>
      )}
    </StyleWrapper>
  )
}

export default Footer

const StyleWrapper = styled.div`
  position: relative;
  padding: 20px;
  background-color: rgb(238, 224, 112);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'source-han-serif-tc';
  font-style: none;
  font-weight: 700;
  ${(props) => props.fixed && `position: fixed`};
  ${(props) => props.fixed && `bottom: 0`};
  ${(props) => props.fixed && `width: 100%`};
`
const FooterContent = styled.div`
  line-height: 25px;
`
const FooterText = styled.div`
  font-size: ${isBrowser ? 18 : 12}px;
  text-align: center;
  letter-spacing: 3.2px;
`
const FooterSocialMedial = styled.div`
  position: absolute;
  right: 120px;
  margin: auto;
  width: 158px;
  display: flex;
  justify-content: space-around;
  img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
`
