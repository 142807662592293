import React from 'react'
import styled from 'styled-components'

function Teacher({ imgSrc, description, name, major }) {
  return (
    <StyleWrapper>
      <TeacherImg src={imgSrc} />
      <Description>{description}</Description>
      <Name>{name}</Name>
      <Major>{major}</Major>
    </StyleWrapper>
  )
}

export default Teacher

const StyleWrapper = styled.div`
  position: relative;
  max-width: 1336px;
  min-width: 100vw;
  width: 100vw;
  height: 100vh;
  padding: 10px;
  font-family: 'source-han-serif-tc';
  font-style: none;
`
const Description = styled.div`
  text-align: left;
  letter-spacing: 1.8px;
  font-size: 18px;
  line-height: 36px;
  position: absolute;
  right: 90px;
  bottom: 0px;
  transform: translate(-340px, -340px);
  font-weight: 400;
`
const Name = styled.div`
  font-size: 48px;
  line-height: 30px;
  letter-spacing: 4.8px;
  position: absolute;
  right: 90px;
  bottom: 0px;
  transform: translate(-375px, -243px);
  font-weight: 700;
`
const Major = styled.div`
  text-align: center;
  letter-spacing: 1.8px;
  font-size: 18px;
  line-height: 30px;
  position: absolute;
  right: 90px;
  bottom: 0px;
  transform: translate(-270px, -150px);
  font-weight: 400;
`
const TeacherImg = styled.img`
  position: absolute;
  bottom: 0px;
  right: 90px;
`
