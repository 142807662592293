/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation, Link } from 'react-router-dom'
import InstagramEmbed from 'react-instagram-embed'
import { useRecoilState } from 'recoil'
import YouTube from '@u-wave/react-youtube'
import { get } from 'lodash'
import { isMobile, isBrowser } from 'react-device-detect'
import { paste, snake } from '../../data/posts'
import TitleImg from '../../images/標題.png'

import WorksSection from '../../components/WorksSection'
// import CircleAudioPlayer from '../../components/CircleAudioPlayer'
import axios from 'axios'
import { worksDataState } from '../../atoms/recoil-atoms'

function Works() {
  // const teamTypes = ['graphic', 'video', 'audio']
  const location = useLocation()
  const pathNames = location.pathname.split('/')
  // const parentPath = pathNames[pathNames.length - 2]
  const currentPath = pathNames[pathNames.length - 1]
  const [apiData, setApiData] = useRecoilState(worksDataState)

  const dataFormatter = apiData => {
    const masterArr =
      get(apiData, '[0][16]', []).length > 0
        ? get(apiData, '[0][16]', []).split('\n')
        : []
    const masterObj =
      masterArr.length > 0
        ? masterArr.reduce((arr, current) => {
            const src = get(current.split('#'), '[1]', null)
            const position = get(current.split('#'), '[0]', null).split('/')[1]
              ? get(current.split('#'), '[0]', null).split('/')[1]
              : null
            const principal = get(current.split('#'), '[0]', null)
              ? get(current.split('#'), '[0]', null).split('/')[0]
              : null
            return [...arr, { src, position, principal }]
          }, [])
        : []
    const roleArr =
      get(apiData, '[0][17]', []).length > 0
        ? get(apiData, '[0][17]', []).split('\n')
        : []
    const roleObj =
      roleArr.length > 0
        ? roleArr.reduce((arr, current) => {
            const src = get(current.split('#'), '[1]', null)
            const position = get(current.split('#'), '[0]', null).split('/')[1]
              ? get(current.split('#'), '[0]', null).split('/')[1]
              : null
            const principal = get(current.split('#'), '[0]', null)
              ? get(current.split('#'), '[0]', null).split('/')[0]
              : null
            return [...arr, { src, position, principal }]
          }, [])
        : []

    return {
      file: {
        name: get(apiData, '[0][0]', ''),
        bg: get(apiData, '[0][1]', ''),
        link: {
          fb: get(apiData, '[0][13]', ''),
          ig: get(apiData, '[0][12]', ''),
          yt: get(apiData, '[0][14]', ''),
          share: ``
        },
        content: {
          title: get(apiData, '[0][2]', ''),
          article: get(apiData, '[0][3]', '').split('\n'),
          slogan: get(apiData, '[0][4]', ''),
          master: masterObj,
          // get(apiData, '[0][16]', []).split('\n')
          //     .filter(item => item !== ''),
          role: roleObj,
          learnMore: get(apiData, '[0][10]', []),
          source: {
            text:
              get(apiData, '[0][0]', '') === 'Coordinates: Thailand'
                ? [
                    get(apiData, '[0][5]', '')
                      .split('\n')
                      .filter(item => item !== '')
                      .slice(0, 3),
                    get(apiData, '[0][5]', '')
                      .split('\n')
                      .filter(item => item !== '')
                      .slice(3, 6),
                    get(apiData, '[0][5]', '')
                      .split('\n')
                      .filter(item => item !== '')
                      .slice(6, 9)
                  ]
                : get(apiData, '[0][5]', '')
                    .split('\n')
                    .filter(item => item !== ''),
            [`${get(apiData, '[0][11]', '')}`]: get(apiData, '[0][6]', '')
              .split('\n')
              .map(item => {
                return get(apiData, '[0][11]', '') === 'video'
                  ? item.replace('https://youtu.be/', '')
                  : item
              })
              .filter(item => item !== '')
          }
        },

        info: {
          name: get(apiData, '[0][7]', ''),
          type: get(apiData, '[0][8]', ''),
          videoLength: get(apiData, '[0][9]', ''),
          gallery:
            get(apiData, '[0][18]', []).length > 0
              ? get(apiData, '[0][18]', [])
                  .split('\n')
                  .filter(item => item !== '')
              : []
        }
      }
    }
  }

  const currentData = useMemo(() => {
    if (apiData.length === 0) return
    const testData = apiData.filter(item => {
      return item[0] === currentPath
    })
    return dataFormatter(testData).file
  }, [apiData, currentPath])

  // console.log(get(currentData, 'content.role', []))

  React.useEffect(() => {
    let callApi = false
    if (typeof apiData === 'object' && apiData.length === 0 && !callApi) {
      callApi = true
      axios({
        method: 'GET',
        url:
          'https://script.google.com/macros/s/AKfycbzSt-3g7sEZZch35TreSZo_c1kplgDKsKde9Sx_pS3BmeNt6KU/exec?sheet=2',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(r => {
        callApi = false
        const data = r.data
        setApiData(data.slice(2, 22))
      })
    }
    return () => {
      callApi = false
    }
  }, [apiData, setApiData])
  return (
    <Wrapper
      bg={get(currentData, 'bg', '')}
      // color={['新思潮運動', '〈水族〉'].includes(get(currentData, 'name', ''))}
      darkColor={['光裡有你', '便利貼'].includes(get(currentData, 'name', ''))}
      light={get(currentData, 'name', '') === '便利貼'}
      darkBg={get(currentData, 'name', '') === 'instagram'}
    >
      {isMobile && (
        <div style={{ zIndex: 9 }}>
          <BackButton to="/departments">
            <img
              style={{ width: 65, marginTop: 15 }}
              src="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/back1.png"
            />
          </BackButton>
          <img
            style={{
              width: '50%',
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, -20px)'
            }}
            src={TitleImg}
          />
        </div>
      )}
      {currentData && (
        <Fragment>
          <WorksSection
            // strokeX={get(currentData, 'strokeX', 0)}
            type="intro"
            {...currentData}
          />
          <WorksSection type="slogan" {...currentData} />
          {currentData.name !== '出獅了！Lion Dance Craft' && (
            <>
              <WorksSection type="source" {...currentData}>
                {() => (
                  <>
                    {get(currentData, 'content.source.text', null) !== null &&
                      currentData.content.source.hasOwnProperty('text') &&
                      currentData.content.source.text.map((item, index) => {
                        return (
                          <div>
                            {currentData.content.source.hasOwnProperty(
                              'video'
                            ) && (
                              <VideoBox
                                name={[`〈水族〉`, `破劫`].includes(
                                  get(currentData, 'name', '')
                                )}
                              >
                                {currentData.name === 'Coordinates: Thailand' &&
                                  item.map((title, titleIdx) => (
                                    <VideoTitle
                                      style={{
                                        marginBottom: '0',
                                        fontSize: titleIdx === 2 ? '18px' : ''
                                      }}
                                    >
                                      {title}
                                    </VideoTitle>
                                  ))}
                                {currentData.name !==
                                  'Coordinates: Thailand' && (
                                  <VideoTitle>{item}</VideoTitle>
                                )}
                                <PlayerRatio>
                                  <PlayerWrap>
                                    <YouTube
                                      video={get(
                                        currentData,
                                        `content.source.video[${index}]`,
                                        ''
                                      )}
                                      width={isBrowser ? 690 : '100%'}
                                      height={isBrowser ? 460 : '100%'}
                                      // autoplay
                                    />
                                  </PlayerWrap>
                                </PlayerRatio>
                                {currentData.name === 'Coordinates: Thailand' &&
                                  index === 0 && (
                                    <AvatarBox>
                                      {get(currentData, 'content.role', [])
                                        .length > 0 &&
                                        currentData.content.role
                                          .filter(
                                            (item, idx) => item.src !== null
                                          )
                                          .slice(0, 4)
                                          .map((avatar, idx) =>
                                            !get(avatar, 'src', null) ? null : (
                                              <Avatar
                                                key={`${avatar.position}${[
                                                  idx
                                                ]}`}
                                              >
                                                <AvatarImg src={avatar.src} />
                                                {get(
                                                  avatar,
                                                  'position',
                                                  null
                                                ) && (
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      bottom: '20px',
                                                      fontSize: '14px',
                                                      color: '#000',
                                                      zIndex: 10,
                                                      opacity: 0,
                                                      transition: 'all .5s'
                                                    }}
                                                  >
                                                    {avatar.position}
                                                  </div>
                                                )}
                                                {get(
                                                  avatar,
                                                  'principal',
                                                  null
                                                ) && (
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      bottom:
                                                        get(
                                                          avatar,
                                                          'principal',
                                                          []
                                                        ).length > 4
                                                          ? '6px'
                                                          : '2px',
                                                      fontSize:
                                                        get(
                                                          avatar,
                                                          'principal',
                                                          []
                                                        ).length > 4
                                                          ? '6px'
                                                          : '14px',
                                                      color: '#000',
                                                      zIndex: 10,
                                                      opacity: 0,
                                                      transition: 'all .5s'
                                                    }}
                                                  >
                                                    {avatar.principal}
                                                  </div>
                                                )}
                                              </Avatar>
                                            )
                                          )}
                                    </AvatarBox>
                                  )}
                                {currentData.name === 'Coordinates: Thailand' &&
                                  index === 1 && (
                                    <AvatarBox>
                                      {get(currentData, 'content.role', [])
                                        .length > 0 &&
                                        currentData.content.role
                                          .slice(5, 11)
                                          .filter(
                                            (item, idx) => item.src !== null
                                          )
                                          .map((avatar, idx) =>
                                            !get(avatar, 'src', null) ? null : (
                                              <Avatar
                                                key={`${avatar.position}${[
                                                  idx
                                                ]}`}
                                              >
                                                <AvatarImg src={avatar.src} />
                                                {get(
                                                  avatar,
                                                  'position',
                                                  null
                                                ) && (
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      bottom: '20px',
                                                      fontSize: '14px',
                                                      color: '#000',
                                                      zIndex: 10,
                                                      opacity: 0,
                                                      transition: 'all .5s'
                                                    }}
                                                  >
                                                    {avatar.position}
                                                  </div>
                                                )}
                                                {get(
                                                  avatar,
                                                  'principal',
                                                  null
                                                ) && (
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      bottom:
                                                        get(
                                                          avatar,
                                                          'principal',
                                                          []
                                                        ).length > 4
                                                          ? '6px'
                                                          : '2px',
                                                      fontSize:
                                                        get(
                                                          avatar,
                                                          'principal',
                                                          []
                                                        ).length > 4
                                                          ? '6px'
                                                          : '14px',
                                                      color: '#000',
                                                      zIndex: 10,
                                                      opacity: 0,
                                                      transition: 'all .5s'
                                                    }}
                                                  >
                                                    {avatar.principal}
                                                  </div>
                                                )}
                                              </Avatar>
                                            )
                                          )}
                                    </AvatarBox>
                                  )}
                                {currentData.name === 'Coordinates: Thailand' &&
                                  index === 2 && (
                                    <AvatarBox>
                                      {get(currentData, 'content.role', [])
                                        .length > 0 &&
                                        currentData.content.role
                                          .slice(11, 17)
                                          .filter(
                                            (item, idx) => item.src !== null
                                          )
                                          .map((avatar, idx) =>
                                            !get(avatar, 'src', null) ? null : (
                                              <Avatar
                                                key={`${avatar.position}${[
                                                  idx
                                                ]}`}
                                              >
                                                <AvatarImg src={avatar.src} />
                                                {get(
                                                  avatar,
                                                  'position',
                                                  null
                                                ) && (
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      bottom: '20px',
                                                      fontSize: '14px',
                                                      color: '#000',
                                                      zIndex: 10,
                                                      opacity: 0,
                                                      transition: 'all .5s'
                                                    }}
                                                  >
                                                    {avatar.position}
                                                  </div>
                                                )}
                                                {get(
                                                  avatar,
                                                  'principal',
                                                  null
                                                ) && (
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      bottom:
                                                        get(
                                                          avatar,
                                                          'principal',
                                                          []
                                                        ).length > 4
                                                          ? '6px'
                                                          : '2px',
                                                      fontSize:
                                                        get(
                                                          avatar,
                                                          'principal',
                                                          []
                                                        ).length > 4
                                                          ? '6px'
                                                          : '14px',
                                                      color: '#000',
                                                      zIndex: 10,
                                                      opacity: 0,
                                                      transition: 'all .5s'
                                                    }}
                                                  >
                                                    {avatar.principal}
                                                  </div>
                                                )}
                                              </Avatar>
                                            )
                                          )}
                                    </AvatarBox>
                                  )}
                                {currentData.content.source.text.length - 1 ===
                                  index &&
                                  currentData.name !==
                                    'Coordinates: Thailand' && (
                                    <AvatarBox>
                                      {get(currentData, 'content.role', [])
                                        .length > 0 &&
                                        currentData.content.role.map(
                                          (avatar, idx) =>
                                            !get(avatar, 'src', null) ? null : (
                                              <Avatar
                                                key={`${avatar.position}${[
                                                  idx
                                                ]}`}
                                                name={`${currentData.name}`}
                                              >
                                                <AvatarImg src={avatar.src} />
                                                {get(
                                                  avatar,
                                                  'position',
                                                  null
                                                ) && (
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      bottom: '20px',
                                                      fontSize: '14px',
                                                      color: '#000',
                                                      zIndex: 10,
                                                      opacity: 0,
                                                      transition: 'all .5s'
                                                    }}
                                                  >
                                                    {avatar.position}
                                                  </div>
                                                )}
                                                {get(
                                                  avatar,
                                                  'principal',
                                                  null
                                                ) && (
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      bottom:
                                                        get(
                                                          avatar,
                                                          'principal',
                                                          []
                                                        ).length > 4
                                                          ? '6px'
                                                          : '2px',
                                                      fontSize:
                                                        get(
                                                          avatar,
                                                          'principal',
                                                          []
                                                        ).length > 4
                                                          ? '6px'
                                                          : '14px',
                                                      color: '#000',
                                                      zIndex: 10,
                                                      opacity: 0,
                                                      transition: 'all .5s'
                                                    }}
                                                  >
                                                    {avatar.principal}
                                                  </div>
                                                )}
                                              </Avatar>
                                            )
                                        )}
                                    </AvatarBox>
                                  )}
                              </VideoBox>
                            )}
                            {currentData.content.source.hasOwnProperty(
                              'audio'
                            ) && (
                              <div style={{ width: '100%' }}>
                                {get(
                                  currentData,
                                  'content.source.text',
                                  null
                                ) && (
                                  <AudioBox>
                                    <AudioText>{item}</AudioText>
                                    <div
                                      className="container"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          currentData.content.source.audio[
                                            index
                                          ]
                                      }}
                                    ></div>
                                  </AudioBox>
                                  // <AudioBox>
                                  //   <CircleAudioPlayer
                                  //     src={
                                  //       currentData.content.source.audio[
                                  //         index
                                  //       ]
                                  //     }
                                  //   />
                                  //   <AudioText>{item}</AudioText>
                                  // </AudioBox>
                                )}
                              </div>
                            )}
                            {currentData.content.source.hasOwnProperty(
                              'graphic'
                            ) &&
                            get(
                              currentData,
                              `content.source.graphic[${index}]`,
                              ''
                            ).indexOf('iframe') > -1 ? (
                              <div
                                className="container"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    currentData.content.source.graphic[index]
                                }}
                              ></div>
                            ) : get(
                                currentData,
                                `content.source.graphic[${index}]`,
                                ''
                              ).indexOf('youtu.be') > -1 ? (
                              <VideoBox
                                name={[`〈水族〉`, `破劫`].includes(
                                  get(currentData, 'name', '')
                                )}
                              >
                                <VideoTitle>{item}</VideoTitle>
                                <PlayerRatio>
                                  <PlayerWrap>
                                    <YouTube
                                      video={`${get(
                                        currentData,
                                        `content.source.graphic[${index}]`,
                                        ''
                                      ).replace('https://youtu.be/', '')}`}
                                      width={isBrowser ? 690 : '100%'}
                                      height={isBrowser ? 460 : '100%'}
                                      // autoplay
                                    />
                                  </PlayerWrap>
                                </PlayerRatio>
                              </VideoBox>
                            ) : get(
                                currentData,
                                `content.source.graphic[${index}]`,
                                ''
                              ).indexOf('instagram') > -1 ? (
                              <div style={{ width: '100%' }}>
                                <VideoTitle>{item}</VideoTitle>
                                <InstagramEmbed
                                  url={`${get(
                                    currentData,
                                    `content.source.graphic[${index}]`,
                                    ''
                                  ).replace(
                                    'www.instagram.com',
                                    'instagr.am'
                                  )}`}
                                  maxWidth={720}
                                  hideCaption={true}
                                  containerTagName="div"
                                  protocol=""
                                  injectScript
                                />
                              </div>
                            ) : get(
                                currentData,
                                `content.source.graphic[${index}]`,
                                ''
                              ).indexOf('youtube') > -1 ? (
                              <div style={{ width: '100%' }}>
                                {currentData.name !==
                                  'Coordinates: Thailand' && (
                                  <VideoTitle>{item}</VideoTitle>
                                )}
                                <PlayerRatio>
                                  <PlayerWrap>
                                    <YouTube
                                      video={get(
                                        currentData,
                                        `content.source.graphic[${index}]`,
                                        ''
                                      ).replace('https://youtu.be/','')}
                                      width={isBrowser ? 690 : '100%'}
                                      height={isBrowser ? 460 : '100%'}
                                      // autoplay
                                    />
                                  </PlayerWrap>
                                </PlayerRatio>
                              </div>
                            ) : null}
                          </div>
                        )
                      })}
                  </>
                )}
              </WorksSection>
              {/* <WorksSection {...currentData} /> */}
            </>
          )}
        </Fragment>
      )}
    </Wrapper>
  )
}

export default Works

const Wrapper = styled.div`
  width: 100%;
  height: ${isBrowser ? `` : `100%`};
  box-sizing: border-box;
  // overflow: auto;
  /* color: ${props =>
    props.color && !props.darkColor
      ? '#666'
      : !props.color && props.darkColor
      ? '#333'
      : '#fff'}; */
  color:#fff;
  font-family: 'source-han-serif-tc';
  font-style: normal;
  font-weight: 700;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${props => `url('${props.bg}')`};
    opacity: ${props => (props.light ? 0.5 : props.darkBg ? 1 : 0.9)};
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.5);
  }
`

const BackButton = styled(Link)`
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 50px;
  height: 40px;
  color: #fff;
  left: 14px;
  top: 18px;
  & i {
    font-size: 20px;
  }
  & span {
    font-size: 16px;
    font-weight: 700;
  }
`

// const graphicText = styled.p`
//   font-size: 16px;
//   line-height: 1.5;
// `

const VideoBox = styled.div`
  width: 100%;
  ${props => isMobile && props.name && `width: 100vw;`}
  margin-bottom: 40px;
`

const VideoTitle = styled.p`
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-family: 'lantin';
  font-weight: normal;
  ${isMobile && `text-align: left;font-size: 14px;`};
`

const PlayerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
`

const PlayerRatio = styled.div`
  position: relative;
  width: ${isBrowser ? '90%' : '100%'};
  &::before {
    content: '';
    display: block;
    padding-top: calc(90% * (3 / 4));
  }
`

const AudioBox = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  .container {
    width: 100%;
    padding-right: 2%;
  }
`

const AudioText = styled.div`
  font-size: 26px;
  font-family: 'lantin';
`
const AvatarBox = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 20px;
  margin-top: -52px;
  ${isMobile && `padding-right: 0;padding-left:30px;`};
  ${isMobile && `margin: 30px auto 0 auto;justify-content: center;`};
`

const Avatar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 101px;
  height: 101px;
  ${isMobile &&
    `width: 55px;
  height: 55px;`}
  overflow: hidden;
  background-position: center;
  background-size: 200%;
  margin-right: ${props => (props.name === '霓虹 霓虹' ? '20px' : '30px')};
  margin-bottom: 20px;
  overflow: hidden;
  ${isBrowser &&
    `
       &:hover {
    &:after {
      opacity: 0.5;
    }
    & > div {
      opacity: 1 !important;
    }
  }
  &:after {
    content: '';
    color: white;
    opacity: 0;
    position: absolute;
    bottom: 0.2px;
    left: 0.5px;
    border: -1px solid rgb(255, 255, 153);
    width: 100px;
    height: 50px; /* as the half of the width */
    background: rgb(255, 255, 153);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom: 0;
    transition: all 0.5s;
  }
  `}
`

const AvatarImg = styled.img`
  width: 100%;
  height: auto;
`

const Container = styled.div`
  position: relative;
  padding: 20px;
`
