import React from 'react'
import styled from 'styled-components'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import { useLocation, Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import axios from 'axios'
import 'intersection-observer'

import TeacherImg from '../../images/tea1.png'
import TeacherLinImg from '../../images/tea2.png'
import PageScrollingControl from '../../utils/pageScrollingControl'
import RecordButton from '../../components/RecordButton'
import InfoModal from '../../components/InfoModal'
import TeacherInfoModal from '../../components/TeacherInfoModal'
import SectionPart from '../../components/SectionPart'
import SideBar from '../../components/SideBar'
import Hamburger from '../../components/Hamburger'
import LandingTitleCanvas from '../../components/LandingTitleCanvas'
import SponsorList from '../../components/SponsorList'
import Footer from '../../components/Footer'
import LifeShowImg from '../../images/直播中1.png'
import {
  menuState,
  teacherInfoModalIsOpenState,
  teacherInfoModalDataState,
} from '../../atoms/recoil-atoms'
import data from '../../data/people.json'

function useInterval(callback, delay) {
  const savedCallback = React.useRef()

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const pageIDs = [
  'landing',
  'reception',
  'reception-2',
  'reception-3',
  'departments',
  'records',
  // 'dockers',
  'sponsors',
]
const images = [
  'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/m-01.gif',
  // 'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/m-02.jpg',
  // 'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/m-03.jpg',
  // 'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/m-04.jpg',
  // 'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/m-05.jpg',
  // 'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/m-06.jpg',
  // 'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/m-07.jpg',
  // 'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/m-08.jpg',
  // 'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/m-09.jpg',
]

function IndexPage() {
  const [, setIsOpenModal] = React.useState(false)
  const [, setPersonIndex] = React.useState(0)
  const [menu, setMenuState] = useRecoilState(menuState)
  const [, setTeacherModalIsOpen] = useRecoilState(teacherInfoModalIsOpenState)
  const [, setTeacher] = useRecoilState(teacherInfoModalDataState)
  const [, setPage] = React.useState(0)
  const pageScrollingControl = React.useRef()
  const [runningClip] = React.useState(null)
  const [backgroundImageIndex, setBackgroundImageIndex] = React.useState(0)
  const [isLiveTime, setIsLiveTime] = React.useState(false)
  const [liveLink, setLiveLink] = React.useState('')
  const location = useLocation()
  React.useEffect(() => {
    pageScrollingControl.current = new PageScrollingControl({
      pageIds: pageIDs,
      page: 0,
    })
  }, [location.hash])
  React.useEffect(() => {
    axios({
      method: 'GET',
      url:
        'https://script.google.com/macros/s/AKfycbzSt-3g7sEZZch35TreSZo_c1kplgDKsKde9Sx_pS3BmeNt6KU/exec?sheet=4',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((r) => {
      const data = r.data
      if (data[1][0] === '是') {
        setIsLiveTime(true)
        setLiveLink(data[1][1])
      }
    })
  }, [])
  useInterval(() => {
    let nIndex = backgroundImageIndex + 1
    if (nIndex >= images.length) {
      nIndex = 0
    }
    setBackgroundImageIndex(nIndex)
  }, runningClip)
  return (
    <ReactScrollWheelHandler onMouseDown={() => {}}>
      <StyleContainer>
        <SectionPart
          anchorId="landing"
          content={
            <React.Fragment>
              <CoverImg src="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/%E5%B0%81%E9%9D%A2M.png" />
              <ScrollToDown
                onClick={() => {
                  pageScrollingControl.current.pageDown(0)
                  setPage(0)
                }}
              >
                Scroll For Enter
              </ScrollToDown>
            </React.Fragment>
          }
        />
        <SectionPart
          anchorId="reception"
          desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E7%B7%A3%E8%B5%B7.png"
          content={
            <div>
              <div>起源於希臘劇作家亞里斯多分・尼茲</div>
              <div>在作品《The Birds》中提及的「雲裡杜鵑窩」，</div>
              <div>後來被解讀為幻想中的世外桃源。</div>
            </div>
          }
        />
        <SectionPart
          isMobile
          anchorId="reception-2"
          desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E8%87%B4%E6%95%AC.png"
          content={
            <div>
              <div>1975年美國電影《飛越杜鵑窩》</div>
              <div>描述精神病患在精神病院裡反抗體制，</div>
              <div>杜鵑窩從此代指精神病院。</div>
              <div>我們想借用精神病院比喻我們獨特性格，</div>
              <div>在他人眼裡也許覺得我們怪誕不經，</div>
              <div>但在我們的世界裡，</div>
              <div>「杜鵑窩」是讓我們肆意揮灑自我的樂園。</div>
            </div>
          }
        />
        <SectionPart
          isMobile
          anchorId="reception-3"
          desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E7%AF%89%E5%9F%8E.png"
          content={
            <div>
              <div>我們充滿幻想和創意，</div>
              <div>腦中擁有著天馬行空的想法，</div>
              <div>有時瘋狂得如同人們眼中的精神病患。</div>
              <div>這次我們想透過創作，</div>
              <div>邀請您來一探究竟我們眼中看見的是什麼樣的世界。</div>
              <div>社會、學校的壓力像是牢籠，</div>
              <div>然而我們仍將日子，</div>
              <div>過得如同置身一座瘋狂有趣的樂園。</div>
            </div>
          }
        />
        <SectionPart
          anchorId="departments"
          content={
            <React.Fragment>
              <ViewAllWorksBackground>
                <img src={images[backgroundImageIndex]} />
              </ViewAllWorksBackground>
              <ViewAllWorksText>
                <Link to="/departments">
                  <img
                    width="50%"
                    height="50%"
                    src="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/ViewAllWorksM.png"
                  />
                </Link>
              </ViewAllWorksText>
            </React.Fragment>
          }
        />
        <SectionPart
          anchorId="records"
          contentStyle={{
            marginTop: -140,
          }}
          content={
            <React.Fragment>
              <DoctorTextImg src="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/records1.png" />
              <RecordWrapper>
                {data.map((person, idx) => {
                  return (
                    <RecordButton
                      key={idx}
                      person={person}
                      idx={idx}
                      onClick={() => {
                        setIsOpenModal(true)
                        setPersonIndex(idx)
                      }}
                    />
                  )
                })}
              </RecordWrapper>
              <div>
                <div>
                  <DoctorTextImg src="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/doctors1.png" />
                </div>
                <img
                  style={{
                    position: 'absolute',
                    width: 90,
                    right: 90,
                    bottom: 50,
                  }}
                  src={TeacherImg}
                  onClick={() => {
                    setTeacherModalIsOpen(true)
                    setTeacher({
                      title: [
                        '英國卡地夫大學新聞媒體與文化研究所博士',
                        '媒體與文化研究／視覺創作與專題研究／電影研究',
                      ],
                      name: '指導老師 邱啓明',
                      description:
                        '啟明老師在這座瘋狂絢爛的杜鵑窩裡，扮演著大家長的角色。他總是用如同罐頭般的爽朗笑聲帶領我們進行激烈的腦部運動。輕鬆詼諧的教導中，蘊含的是啟明老師對於專業領域的底蘊以及春風化雨慈悲心腸，彷彿什麼拐瓜劣棗經過他的巧手改造後，都能變成可以擺在微風超市販售的上等好貨，Got it ?',
                      pic:
                        'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/teacherSmall.jpg',
                    })
                  }}
                />
                <img
                  style={{
                    position: 'absolute',
                    width: 90,
                    left: 90,
                    bottom: 50,
                  }}
                  src={TeacherLinImg}
                  onClick={() => {
                    setTeacherModalIsOpen(true)
                    setTeacher({
                      title: [
                        '北京大學新聞與傳播學院博士',
                        '電視製作／電影產業研究／國際傳播研究',
                      ],
                      name: '指導老師 陳靖霖',
                      description:
                        '每週四傍晚，一樣的緊張，一樣的期待，究竟…明天的討論順序是……?靖霖老師在杜鵑窩是鐵血教練般的存在，如果你在這裡聽見了少男少女的哭聲，是誰害的呢？別懷疑，是靖霖：））雖然靖霖老師很硬派，但是還是深受窩民的愛戴，該指正的地方指正，該稱讚的地方也絕不吝嗇。相信經過靖霖老師諄諄教誨後，杜鵑窩出品，絕對是品質保證。最後…我只想弱弱的問一句：老師，我能畢業了嗎？',
                      pic:
                        'https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/teacherLinSmall.jpg',
                    })
                  }}
                />
              </div>
            </React.Fragment>
          }
        />
        <SectionPart
          isMobile
          anchorId="sponsors"
          desktopTitleImgSrc="https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/images/%E8%B4%8A%E5%8A%A9.png"
          content={
            <React.Fragment>
              <SponsorList
                items={[
                  {
                    src: 'https://i.imgur.com/QVdQckU.png',
                    link: 'https://www.taipeiff.taipei/',
                  },
                  {
                    src: 'https://i.imgur.com/dqJkEws.png',
                    link: 'https://www.mgrstore.net/',
                  },
                  {
                    src: 'https://i.imgur.com/Ly73sY2.png',
                    link: 'https://www.cts.com.tw',
                  },
                ]}
              />
              <SponsorList
                items={[
                  {
                    src: 'https://i.imgur.com/BDb8zU4.png',
                    link: 'https://www.facebook.com/DoubleNiceee/',
                  },
                  {
                    src: 'https://i.imgur.com/vKiFkJ5.png',
                    link: 'https://www.kkbox.com/tw/tc/',
                  },
                  {
                    src: 'https://i.imgur.com/wh1cZgQ.png',
                    link: 'https://www.showtimes.com.tw/',
                  },
                ]}
              />
              <SponsorList
                items={[
                  {
                    src: 'https://i.imgur.com/citNc0f.png',
                    link: 'https://www.tstartel.com/CWS/',
                  },
                  {
                    src: 'https://i.imgur.com/RP8XqWJ.png',
                    link: 'https://www.4gtv.tv/',
                  },
                  {
                    src: 'https://i.imgur.com/EfnMSbp.png',
                    link: 'https://www.facebook.com/nancyno6apt/',
                  },
                ]}
              />
              <SponsorList
                items={[
                  {
                    src: 'https://i.imgur.com/jE9u2SU.png',
                    link: 'https://salary.tw',
                  },
                  {
                    src: 'https://i.imgur.com/wSeJ6Pf.png',
                    link: 'https://interview.tw/',
                  },
                  {
                    src: 'https://i.imgur.com/KUBVMej.png',
                    link: 'https://www.webtoons.com/zh-hant/',
                  },
                ]}
              />
              <SponsorText top={450}>
                <a href="https://rtv.ntua.edu.tw" target="_blank">
                  國立臺灣藝術大學廣播電視學系
                </a>
              </SponsorText>
              <SponsorText top={480}>
                <a
                  href="http://ntuaalu.ntua.edu.tw/mp.asp?mp=9"
                  target="_blank"
                >
                  國立臺灣藝術大學廣播電視學系&nbsp;系友會
                </a>
              </SponsorText>
            </React.Fragment>
          }
        />
        <Hamburger>
          <SideBar
            onClick={(id) => {
              pageScrollingControl.current.page = id
              setPage(pageScrollingControl.current.page)
              setMenuState({
                ...menu,
                isOpen: false,
              })
            }}
          />
        </Hamburger>
        {liveLink && (
          <a href={liveLink}>
            <LifeShow>
              <img src={LifeShowImg} />
              <div
                style={{
                  position: 'absolute',
                  left: 120,
                  top: 90,
                }}
              >
                <div
                  style={{ width: 300, height: 250, cursor: 'pointer' }}
                ></div>
              </div>
            </LifeShow>
          </a>
        )}
        <InfoModal />
        <TeacherInfoModal />
      </StyleContainer>
      <Footer />
    </ReactScrollWheelHandler>
  )
}

const StyleContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  background-image: url(https://dron-ci-builder.s3-ap-northeast-1.amazonaws.com/other-image/BG1.jpg);
  background-position: center;
  background-size: cover;
  flex-direction: column;
`

const CoverImg = styled.img`
  width: 100vw;
  height: 100vh;
`

const ViewAllWorksText = styled.div`
  z-index: 1;
  color: white;
  font-family: Impact;
  font-size: 25px;

  > a {
    text-decoration: none;
    color: white;
  }
`
const ViewAllWorksBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  > img {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
`
const RecordWrapper = styled.div`
  margin-right: 30px;
  display: grid;
  width: 100vw;
  padding: 30px;
  overflow-x: scroll;
  grid-template-columns: repeat(39, 1fr);
  column-gap: 10px;
  -webkit-overflow-scrolling: touch;
`

const SponsorText = styled.div`
  position: absolute;
  font-size: 14px;
  padding: 0 0 0 10px;
  letter-spacing: 3.6px;
  top: ${(props) => props.top}px;
  width: 100%;

  a {
    text-decoration: none;
    color: black;
  }
`

const ScrollToDown = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: 14px;
  width: 200px;
  transform: translate(-50%, -20px);
  padding: 0px 10px 0px 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
  font-family: Impact;
`
const DoctorTextImg = styled.img`
  width: 50%;
`
const LifeShow = styled.div`
  position: fixed;
  bottom: 100px;
  right: 0;
  > img {
    height: 100px;
    cursor: pointer;
  }
`

export default IndexPage
