import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { menuState } from '../../atoms/recoil-atoms'

const root = document.getElementById('root')
const div = document.createElement('div')
function MenuMask(props) {
  const el = React.useRef(div)
  React.useEffect(() => {
    root.appendChild(el.current)
  }, [])
  return createPortal(props.children, el.current)
}
function Hamburger(props) {
  const [menu, setMenuState] = useRecoilState(menuState)
  return (
    <StyleWrapper>
      {menu.isOpen && (
        <MenuMask>
          <div
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, .8)',
              left: 0,
              zIndex: 0,
            }}
          ></div>
        </MenuMask>
      )}
      <nav role="navigation">
        <div id="menuToggle">
          <input
            onChange={(e) =>
              setMenuState({
                ...menu,
                isOpen: e.target.checked,
              })
            }
            checked={menu.isOpen}
            value={menu.isOpen}
            type="checkbox"
          />
          <span></span>
          <span></span>
          <span></span>
          {props.children}
        </div>
      </nav>
    </StyleWrapper>
  )
}

const StyleWrapper = styled.div`
  // position: fixed;
  right: 0;
  z-index: 3;

  #menuToggle {
    display: block;
    position: relative;
    top: 20px;
    left: -30px;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;

    transition: color 0.3s ease;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    z-index: 2;

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    // opacity: 0;
    transform: rotate(0deg) translate(-5px, -2px);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    width: 300px;
    left: -275px;
    margin: 50px 0px 0px 0px;

    // background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(0%, -150%);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    font-size: 22px;
    text-align: center;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:checked ~ ul {
    transform: none;
  }
`

export default Hamburger
